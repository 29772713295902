import axios from 'axios'

export const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 10000
})

if (process.env.NODE_ENV === 'development') {
    localStorage.setItem('firebaseSession', process.env.VUE_APP_API_TOKEN)
}

api.interceptors.request.use((request) => {
    const token = localStorage.getItem('firebaseSession')

    if (token) {
        request.headers.common['Authorization'] = `Bearer ${token}`
    }

    return request
})
