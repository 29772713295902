// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/svg/icons/save.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/svg/icons/trash.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".save-component{width:100%}.save-component .button-component{padding:12px;margin-left:2px}.save-component .fade-in-enter-active,.save-component .fade-in-leave-active{-webkit-transition:all .2s ease;transition:all .2s ease}.save-component .fade-in-enter,.save-component .fade-in-leave-to{opacity:0}.save-component__button{color:#34495e;background-color:#fff;border-radius:4px;-webkit-box-shadow:0 1px 3px 0 rgba(0,0,0,.1)!important;box-shadow:0 1px 3px 0 rgba(0,0,0,.1)!important}.save-component__button:not(.is-disabled):not(.is-loading):hover{background-color:#fff;-webkit-box-shadow:0 4px 12px rgba(0,0,0,.1)!important;box-shadow:0 4px 12px rgba(0,0,0,.1)!important}.save-component__button.is-disabled,.save-component__button.is-loading{background-color:hsla(0,0%,100%,.6)}.save-component__button .button-component__loading{width:14px;height:14px;margin:0}.save-component__button .button-component__loading__ellipse{stroke:#34495e}.save-component__icon{display:inline-block;width:16px;height:16px;margin-top:-2px;background-size:contain}.save-component__icon--save{-webkit-mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-color:#2ecc71}.save-component__icon--save,.save-component__icon--trash{-webkit-mask-position:center;mask-position:center;-webkit-mask-repeat:no-repeat;mask-repeat:no-repeat}.save-component__icon--trash{-webkit-mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-color:#f75851}", ""]);
// Exports
module.exports = exports;
