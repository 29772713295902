const Survey = () => import('@/views/Survey')

const Create = () => import('@/views/Create')

const Logic = () => import('@/views/Logic')

const Design = () => import('@/views/Design')

const Preview = () => import('@/views/Preview')

const Configure = () => import('@/views/configure/Configure')
const ConfigureSettings = () => import('@/views/configure/ConfigureSettings')
const ConfigureNotifications = () => import('@/views/configure/ConfigureNotifications')
const ConfigureKiosk = () => import('@/views/configure/ConfigureKiosk')
const ConfigureIntegrations = () => import('@/views/configure/ConfigureIntegrations')
const ConfigureWebhooks = () => import('@/views/configure/ConfigureWebhooks')
const ConfigureAPI = () => import('@/views/configure/ConfigureAPI')

const Share = () => import('@/views/share/Share')
const ShareLink = () => import('@/views/share/ShareLink')
const ShareSocial = () => import('@/views/share/ShareSocial')
const ShareEmbed = () => import('@/views/share/ShareEmbed')
const SharePopup = () => import('@/views/share/SharePopup')

export default [
    {
        path: '/surveys'
    },
    {
        path: '/trash'
    },
    {
        path: '/overview'
    },
    {
        path: '/survey/:id',
        component: Survey,
        children: [
            {
                path: 'create',
                component: Create
            },
            {
                path: 'logic',
                component: Logic
            },
            {
                path: 'design',
                component: Design
            },
            {
                path: 'preview',
                component: Preview
            },
            {
                path: 'configure',
                component: Configure,
                children: [
                    { path: '', name: 'settings', component: ConfigureSettings },
                    {
                        path: 'notifications',
                        name: 'notifications',
                        component: ConfigureNotifications
                    },
                    { path: 'kiosk', name: 'kiosk', component: ConfigureKiosk },
                    {
                        path: 'integrations',
                        name: 'integrations',
                        component: ConfigureIntegrations
                    },
                    { path: 'webhooks', name: 'webhooks', component: ConfigureWebhooks },
                    { path: 'api', name: 'api', component: ConfigureAPI }
                ]
            },
            {
                path: 'share',
                component: Share,
                children: [
                    { path: '', name: 'link', component: ShareLink },
                    { path: 'social', name: 'social', component: ShareSocial },
                    { path: 'embed', name: 'embed', component: ShareEmbed },
                    { path: 'popup', name: 'popup', component: SharePopup }
                ]
            }
        ]
    }
]
