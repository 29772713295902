function beforeMount(element, binding) {
    const clickOutsideHandler = (event) => {
        if (!element.contains(event.target) && element !== event.target) {
            binding.value(event, element)
        }
    }

    element.__clickOutsideHandler__ = clickOutsideHandler

    document.addEventListener('click', element.__clickOutsideHandler__)
}

function unmounted(element) {
    document.removeEventListener('click', element.__clickOutsideHandler__)
}

const directive = {
    beforeMount,
    unmounted
}

export default directive
