<template>
    <span
        :class="[
            'breadcrumb-item-component',
            { 'is-link': to, 'is-active': active, 'is-disabled': disabled }
        ]"
        @click="handleClick"
    >
        <slot />
    </span>
</template>

<script>
export default {
    name: 'BreadcrumbItemComponent',

    inject: ['breadcrumb'],

    props: {
        to: {
            type: [String, Object],
            default: null
        },

        replace: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        active() {
            const routeName = this.to.split('/')[3]
            const activeRouteName = this.breadcrumb.activeRoute.split('/')[3]

            return routeName === activeRouteName
        }
    },

    methods: {
        handleClick() {
            if (!this.disabled) {
                this.breadcrumb.emitter.emit('item-click', this)
                this.$emit('click', this)
            }
        }
    }
}
</script>

<style lang="scss">
.breadcrumb-item-component {
    position: relative;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    color: #fff;

    font-size: 16px;
    font-weight: 500;
    text-align: center;

    min-width: 30px;
    height: 36px;

    padding: 0 20px 0 30px;
    margin: 0 2px;

    background-color: #5fa9ff;

    &:first-of-type {
        margin-left: 0;

        border-radius: 4px 0 0 4px;
    }

    &:last-of-type {
        margin-right: 0;

        border-radius: 0 4px 4px 0;
    }

    &:not(:last-of-type):before {
        content: '';

        position: absolute;
        top: 5px;
        right: -13px;
        z-index: z-index('above');

        width: 26px;
        height: 26px;

        background: #5fa9ff;
        border-top-right-radius: 4px;
        box-shadow: #579ae6 3px -3px 0;

        transform: rotate(45deg);
    }

    &.is-link:not(.is-disabled) {
        cursor: pointer;

        transition: background-color 0.1s;

        &:not(:last-of-type):before {
            transition: background-color 0.1s;
        }

        &:hover {
            background-color: #ffba59;

            &:not(:last-of-type):before {
                background-color: #ffba59;
            }
        }
    }

    &.is-active {
        background-color: #f90;

        &:not(:last-of-type):before {
            background-color: #f90;
        }
    }

    &.is-disabled {
        color: #337ab7;

        background-color: #83b5ed;

        cursor: not-allowed;

        &:not(:last-of-type):before {
            background-color: #83b5ed;
        }
    }

    &.is-visited {
    }
}
</style>
