<template>
    <teleport v-if="activated" :to="to">
        <slot />
    </teleport>
</template>

<script>
export default {
    name: 'TeleportComponent',

    props: {
        to: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            activated: true
        }
    },

    activated() {
        this.activated = true
    },

    deactivated() {
        this.activated = false
    }
}
</script>
