<template>
    <ul :class="['menu-component', { 'menu-component--vertical': direction === 'vertical' }]">
        <slot />
    </ul>
</template>

<script>
import mitt from 'mitt'

export default {
    name: 'MenuComponent',

    provide() {
        return {
            menu: this
        }
    },

    props: {
        direction: {
            type: String,
            default: 'horizontal',
            validator(value) {
                return ['vertical', 'horizontal'].includes(value)
            }
        },

        defaultActive: {
            type: String,
            default: ''
        },

        router: {
            type: Boolean,
            default: false
        },

        textColor: {
            type: String,
            default: ''
        },

        activeTextColor: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            emitter: mitt(),
            activeIndex: this.defaultActive
        }
    },

    watch: {
        defaultActive(value) {
            this.activeIndex = value
        }
    },

    created() {
        this.emitter.on('item-click', this.handleItemClick)
    },

    beforeUnmount() {
        this.emitter.off('item-click')
    },

    methods: {
        handleItemClick(item) {
            if (item.index) {
                if (this.activeIndex !== item.index) {
                    this.activeIndex = item.index

                    this.$emit('select', item.index, item)

                    if (this.router) {
                        const route = item.route || item.index

                        this.$router.push(route).catch((err) => {
                            return err
                        })
                    }
                }
            }
        }
    }
}
</script>

<style lang="scss">
.menu-component {
    display: flex;

    width: 100%;

    padding: 0;
    margin: 0;

    overflow: hidden;
}

.menu-component--vertical {
    flex-direction: column;

    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.04) 0 0 8px, rgba(0, 0, 0, 0.1) 0 0 4px;
}
</style>
