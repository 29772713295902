<template>
    <div class="test-component">
        <ModalComponent ref="modal" width="900px">
            <template #header>
                <TagComponent> POST {{ request.url }} </TagComponent>
                <TagComponent :type="responseStatusClass">
                    <i
                        v-if="response.status === 200"
                        class="tag-component__icon tag-component__icon--check"
                    />
                    <i v-else class="tag-component__icon tag-component__icon--x" />
                    <span>{{ responseStatus }}</span>
                </TagComponent>
            </template>
            <RowComponent>
                <ColumnComponent>
                    <div class="test-component__heading">
                        <i class="test-component__icon test-component__icon--request" />
                        <span>Request</span>
                    </div>
                    <PrismComponent
                        :code="JSON.stringify(request.headers, null, spaces)"
                        language="javascript"
                    >
                        <template #toolbar>
                            <TagComponent>Headers</TagComponent>
                        </template>
                    </PrismComponent>
                    <PrismComponent
                        :code="JSON.stringify(request.payload, null, spaces)"
                        language="javascript"
                    >
                        <template #toolbar>
                            <TagComponent>Body</TagComponent>
                        </template>
                    </PrismComponent>
                </ColumnComponent>
                <ColumnComponent>
                    <div class="test-component__heading">
                        <i class="test-component__icon test-component__icon--response" />
                        <span>Response</span>
                    </div>
                    <PrismComponent
                        :code="JSON.stringify(response.headers, null, spaces)"
                        language="javascript"
                    >
                        <template #toolbar>
                            <TagComponent>Headers</TagComponent>
                        </template>
                    </PrismComponent>
                    <PrismComponent
                        :code="JSON.stringify(response.body, null, spaces)"
                        language="javascript"
                    >
                        <template #toolbar>
                            <TagComponent>Body</TagComponent>
                        </template>
                    </PrismComponent>
                </ColumnComponent>
            </RowComponent>
        </ModalComponent>
    </div>
</template>

<script>
export default {
    name: 'TestComponent',

    props: {
        spaces: {
            type: Number,
            default: 2
        }
    },

    data() {
        return {
            request: '',
            response: ''
        }
    },

    computed: {
        responseStatusClass() {
            return this.response.status === 200 ? 'success' : 'danger'
        },

        responseStatus() {
            return this.response.status === 200 ? 'SUCCESS' : 'FAILED'
        }
    },

    methods: {
        open({ request, response }) {
            this.request = request
            this.response = response
            this.$refs.modal.open()
        },

        close() {
            this.$refs.modal.close()
        }
    }
}
</script>

<style lang="scss">
.test-component {
    .modal-component__header {
        display: flex;

        justify-content: center;

        height: auto;

        padding: 30px;

        border-bottom: 1px solid #f3f3f3;

        .tag-component {
            display: flex;

            align-items: center;

            font-size: 13px;

            margin: 0 4px;
        }

        .tag-component__icon {
            display: inline-block;

            width: 14px;
            height: 14px;

            background-size: contain;

            margin-right: 6px;
        }

        .tag-component__icon--x {
            /*             @include icon('~@/assets/svg/icons/x.svg'); */
        }

        .tag-component__icon--check {
            @include icon('~@/assets/svg/icons/check.svg');
        }
    }

    .modal-component__body {
        min-height: auto;

        padding: 30px;
    }

    .column-component {
        width: 100%;

        &:not(:last-child) {
            margin-right: 10px;
        }
    }

    .prism-component {
        max-width: 415px;
        height: 400px;

        &:not(:last-child) {
            height: 200px;
            margin-bottom: 5px;
        }

        .tag-component {
            color: #fff;

            background-color: rgba(darken(#2c3e50, 8), 0.85);
        }
    }
}

.test-component__heading {
    display: flex;

    align-items: center;

    color: #34495e;

    font-size: 15px;
    font-weight: 500;

    margin-bottom: 10px;
}

.test-component__icon {
    display: inline-block;

    width: 16px;
    height: 16px;

    margin: -2px 8px 0 0;

    background-size: contain;
}

.test-component__icon--request {
    @include icon('~@/assets/svg/icons/corner-down-right.svg');
}

.test-component__icon--response {
    @include icon('~@/assets/svg/icons/corner-up-left.svg');
}
</style>
