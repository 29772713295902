<template>
    <div class="image-component">
        <transition name="fade-in">
            <slot v-if="loading" name="placeholder">
                <div class="image-component__placeholder" />
            </slot>
            <slot v-else-if="error" name="error" />
            <img v-else class="image-component__inner" v-bind="$attrs" :src="src" :style="style" />
        </transition>
    </div>
</template>

<script>
export default {
    name: 'ImageComponent',

    inheritAttrs: false,

    props: {
        src: {
            type: String,
            default: ''
        },

        fit: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            loading: true,
            error: false,
            imageWidth: 0,
            imageHeight: 0
        }
    },

    computed: {
        style() {
            let style = {}

            style.objectFit = this.fit

            return style
        }
    },

    mounted() {
        this.loadImage()
    },

    methods: {
        loadImage() {
            this.loading = true
            this.error = false

            const image = new Image()

            image.onload = (event) => this.handleLoad(event, image)
            image.onerror = this.handleError.bind(this)

            Object.keys(this.$attrs).forEach((key) => {
                const value = this.$attrs[key]

                image.setAttribute(key, value)
            })

            image.src = this.src
        },

        handleLoad(event, image) {
            this.imageWidth = image.width
            this.imageHeight = image.height
            this.loading = false
        },

        handleError(error) {
            this.loading = false
            this.error = true
            this.$emit('error', error)
        }
    }
}
</script>

<style lang="scss">
.image-component {
    position: relative;

    overflow: hidden;
}

.image-component__placeholder {
    width: 100%;
    height: 100%;
}

.image-component__inner {
    display: block;

    width: 100%;
    height: 100%;
}

.fade-in-enter,
.fade-in-leave-to {
    opacity: 0;
}

.fade-in-enter-active,
.fade-in-leave-active {
    transition: opacity 0.48s cubic-bezier(0.48, 0, 0.12, 1);
}
</style>
