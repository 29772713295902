<template>
    <transition
        name="modal-component"
        :duration="{ enter: 450, leave: 201 }"
        @after-enter="afterEnter"
        @after-leave="afterLeave"
    >
        <div
            v-if="opened"
            v-escape="onPressEscape"
            :class="['modal-component', { 'is-fullscreen': fullscreen }]"
            @click.self="onClickOutside"
        >
            <div
                :class="['modal-component__container', { 'is-fullscreen': fullscreen }]"
                :style="style"
            >
                <div v-if="showClose" class="modal-component__controls">
                    <button class="modal-component__close" @click="onClickClose">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#f75851"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="modal-component__close__icon"
                        >
                            <path d="M18 6L6 18M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div v-if="$slots.header" class="modal-component__header">
                    <slot name="header" />
                </div>
                <div class="modal-component__body">
                    <slot />
                </div>
                <div v-if="$slots.footer" class="modal-component__footer">
                    <slot name="footer" />
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import escape from '@/directives/escape'

export default {
    name: 'ModalComponent',

    directives: {
        escape
    },

    props: {
        visible: {
            type: Boolean,
            default: false
        },

        width: {
            type: String,
            default: '50%'
        },

        closeOnPressEscape: {
            type: Boolean,
            default: true
        },

        closeOnClickOutside: {
            type: Boolean,
            default: true
        },

        showClose: {
            type: Boolean,
            default: true
        },

        fullscreen: {
            type: Boolean,
            default: false
        },

        appendToBody: {
            type: Boolean,
            default: true
        },

        lockScroll: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            opened: false
        }
    },

    computed: {
        style() {
            let style = {}

            if (!this.fullscreen) {
                if (this.width) {
                    style.width = this.width
                }
            }

            return style
        }
    },

    watch: {
        visible: {
            handler(value) {
                value ? this.open() : this.close()
            }
        }
    },

    mounted() {
        if (this.visible) this.open()
    },

    methods: {
        onPressEscape() {
            if (this.closeOnPressEscape && this.opened) {
                this.close()
            }
        },

        onClickOutside() {
            if (this.closeOnClickOutside && this.opened) {
                this.close()
            }
        },

        onClickClose() {
            this.close()
        },

        open() {
            this.opened = true

            this.$emit('open')
        },

        close() {
            this.opened = false
            this.$emit('update:visible', this.opened)
            this.$emit('close')
        },

        afterEnter() {
            this.$emit('opened')
        },

        afterLeave() {
            this.$emit('closed')
        }
    }
}
</script>

<style lang="scss">
.modal-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: z-index('modal');

    background-color: rgba(44, 62, 80, 0.7);

    will-change: background-color, opacity, transform;

    &.is-fullscreen {
        background-color: transparent;
    }
}

.modal-component__container {
    display: flex;
    flex-direction: column;

    position: relative;

    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0 2px 20px rgba(desaturate(#34495e, 20), 0.2);

    &.is-fullscreen {
        width: 100%;
        height: 100%;

        border-radius: 0;
        box-shadow: none;
    }
}

.modal-component__controls {
    position: absolute;
    top: 0;
    right: 0;

    z-index: z-index('modal') + 1;

    padding: 10px;
}

.modal-component__close {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 32px;
    height: 32px;

    padding: 0;

    background-color: #fff;
    border: 0;
    border-radius: 50%;
    outline: 0;
    box-shadow: 0 -1px 4px 3px rgba(0, 0, 0, 0.01), 0 1px 2px 0 rgba(0, 0, 0, 0.1),
        0 3px 6px 2px rgba(0, 0, 0, 0.03);

    user-select: none;

    transition: box-shadow 0.18s ease;

    cursor: pointer;

    &:hover {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.16), 0 0 1px 0 rgba(0, 0, 0, 0.1);
    }
}

.modal-component__header {
    position: relative;

    display: flex;
    align-items: center;

    font-size: 15px;

    height: 115px;

    padding-left: 15px;

    border-radius: 6px 6px 0 0;

    overflow: hidden;
}

.modal-component__body {
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;

    min-height: 400px;

    padding-bottom: 10px;

    .is-fullscreen & {
        height: 100%;
    }
}

.modal-component__footer {
    display: flex;
    justify-content: center;

    padding: 30px;

    border-top: 1px solid #eaeaea;
}

.modal-component-enter-active,
.modal-component-leave-active {
    transition: background-color 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.001s;
}

.modal-component-enter-active .modal-component__container {
    transition: opacity 0.35s cubic-bezier(0.77, 0, 0.175, 1) 0.1s,
        transform 0.35s cubic-bezier(0.77, 0, 0.175, 1) 0.1s;
}

.modal-component-leave-active .modal-component__container {
    transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0s,
        transform 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0s;
}

.modal-component-enter,
.modal-component-leave-to {
    background-color: rgba(0, 0, 0, 0);

    .modal-component__container {
        opacity: 0;

        transform: scale(0.85);

        &.is-fullscreen {
            transform: none;
        }
    }
}
</style>
