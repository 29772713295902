<template>
    <RouterView />
    <ThemeComponent />
</template>

<script>
export default {
    name: 'App',

    metaInfo: {
        titleTemplate: (titleChunk) => {
            return titleChunk ? `${titleChunk} · SurveyLegend` : 'SurveyLegend'
        }
    }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Hind:400,500,600&display=swap');
@import '@/assets/scss/base/_normalize';

#app {
    position: relative;

    @include font-hind;
}
</style>
