import NotificationService from '@/services/notification-service'

const state = () => ({
    instantNotifications: [],
    summaryNotifications: []
})

const getters = {
    instantNotifications: (state) => state.instantNotifications,
    summaryNotifications: (state) => state.summaryNotifications
}

const actions = {
    async fetchNotifications({ commit }, payload) {
        const { data } = await NotificationService.listNotificationEmails(payload)

        commit('setNotifications', { notifications: data })
    },

    async addNotification({ commit }, payload) {
        const { index, notification } = payload
        const { data } = await NotificationService.addNotificationEmail({
            survey_id: notification.survey_id,
            data: notification
        })

        commit('addNotification', {
            notification: {
                index,
                type: notification.type,
                data
            }
        })
    },

    async updateNotification({ commit }, payload) {
        const { index, notification } = payload
        const { data } = await NotificationService.updateNotificationEmail({
            id: notification.id,
            survey_id: notification.survey_id,
            data: {
                email: notification.email
            }
        })

        commit('updateNotification', {
            notification: {
                index,
                type: notification.type,
                data
            }
        })
    },

    async deleteNotification({ commit }, payload) {
        const { notification } = payload

        await NotificationService.deleteNotificationEmail({
            id: notification.id,
            survey_id: notification.survey_id
        })

        commit('deleteNotification', payload)
    }
}

const mutations = {
    setNotifications(state, { notifications }) {
        const instant = notifications.filter((notification) => notification.type === 'instant')
        const summary = notifications.filter((notification) => notification.type === 'summary')

        state.instantNotifications = instant
        state.summaryNotifications = summary
    },

    addNotification(state, { notification }) {
        const { type } = notification

        state[`${type}Notifications`].push(notification)
    },

    updateNotification(state, { notification }) {
        const { index, type, data } = notification

        state[`${type}Notifications`].splice(index, 1, data)
    },

    deleteNotification(state, payload) {
        const {
            index,
            notification: { type }
        } = payload

        state[`${type}Notifications`].splice(index, 1)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
