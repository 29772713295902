<template>
    <li
        :class="[
            'dropdown-item-component',
            {
                'dropdown-item-component--divided': divided,
                'is-readonly': readonly,
                'is-disabled': disabled
            }
        ]"
        @click="handleClick"
    >
        <slot />
    </li>
</template>

<script>
export default {
    name: 'DropdownItemComponent',

    inject: ['dropdown'],

    props: {
        divided: {
            type: Boolean,
            default: false
        },

        readonly: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        },

        hideOnClick: {
            type: Boolean,
            default: true
        }
    },

    methods: {
        handleClick() {
            if (!this.disabled && !this.readonly) {
                this.dropdown.emitter.emit('item-click', this)
                this.$emit('click', this)
            }
        }
    }
}
</script>

<style lang="scss">
.dropdown-item-component {
    display: flex;
    flex-direction: row;
    align-items: center;

    color: #34495e;

    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    white-space: nowrap;

    padding: 8px 16px;

    cursor: pointer;

    &:not(.is-readonly):hover {
        color: #34495e;

        background-color: #f9f9f9;
    }

    &.is-readonly {
        cursor: default;
    }
}

.dropdown-item-component--divided {
    position: relative;

    padding-top: 12px;
    margin-top: 4px;
    border-top: 1px solid #f1f1f1;

    &:before {
        content: '';

        position: absolute;

        top: 0;

        width: 100%;
        height: 4px;

        margin: 0 -16px;

        background-color: #fff;
    }
}
</style>
