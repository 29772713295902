<template>
    <li class="menu-sub-component">
        <div class="menu-sub-component__title" :style="titleStyle">
            <template v-if="!$slots.title">
                <span>{{ title }}</span>
                <i class="menu-sub-component__icon" :style="iconStyle" />
            </template>
            <slot v-else name="title" />
        </div>
        <ul class="menu-sub-component__flyout">
            <slot />
        </ul>
    </li>
</template>

<script>
export default {
    name: 'MenuSubComponent',

    inject: ['menu'],

    props: {
        title: {
            type: String,
            default: ''
        }
    },

    computed: {
        textColor() {
            return this.menu.textColor || ''
        },

        activeTextColor() {
            return this.menu.activeTextColor || ''
        },

        titleStyle() {
            const style = {}

            style.color = this.active ? this.activeTextColor : this.textColor

            return style
        },

        iconStyle() {
            const style = {}

            style.backgroundColor = this.active ? this.activeTextColor : this.textColor

            return style
        }
    }
}
</script>

<style lang="scss">
.menu-sub-component {
    list-style-type: none;
}

.menu-sub-component__title {
    display: flex;

    align-items: center;

    color: #34495e;

    font-size: 15px;
    font-weight: 500;

    padding: 10px 20px;

    cursor: pointer;

    &:hover:not(:focus):not(.is-active):not(.is-disabled) {
        opacity: 0.6;
    }

    &:focus,
    &.is-active {
        color: #de524b;
    }

    &.is-disabled {
        cursor: not-allowed;
    }
}

.menu-sub-component__icon {
    @include mask-icon('~@/assets/svg/icons/chevron-down.svg');

    display: inline-block;

    width: 16px;
    height: 16px;

    margin-left: 4px;

    mask-size: contain;
    background-color: #34495e;
}
</style>
