<template>
    <div v-click-outside="handleClickOutside" class="select-component">
        <button
            :class="[
                'select-component__button',
                { 'is-open': open, 'is-selected': value, 'is-disabled': disabled }
            ]"
            @click="handleClick"
        >
            <span>
                <template v-if="!value">{{ placeholder }}</template>
                <template v-else>{{ value }}</template>
            </span>
            <i class="select-component__icon" />
        </button>
        <transition name="select-component">
            <ul v-if="open" class="select-component__options">
                <div v-if="filterable" class="select-component__filter">
                    <svg
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                    </svg>
                    <input v-model="filter" ref="filter" class="select-component__filter__inner" />
                </div>
                <li
                    v-for="(option, index) in computedOptions"
                    :key="index"
                    :class="['select-component__option', { 'is-active': option.label === value }]"
                    @click="handleClickOption(option)"
                >
                    <div class="select-component__option__inner">
                        <slot :option="option">{{ option.label }}</slot>
                    </div>
                </li>
            </ul>
        </transition>
    </div>
</template>

<script>
import clickOutside from '@/directives/clickOutside'

export default {
    name: 'SelectComponent',

    directives: {
        clickOutside
    },

    props: {
        selected: {
            type: String,
            default: ''
        },

        placeholder: {
            type: String,
            default: 'Select'
        },

        footerButton: {
            type: [String, Boolean],
            default: false
        },

        options: {
            type: Array,
            default: () => []
        },

        filterable: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            value: '',
            open: false,
            filter: ''
        }
    },

    computed: {
        computedOptions() {
            const options = this.footerButton
                ? this.options.concat([
                      {
                          label: this.footerButton,
                          footerButton: true
                      }
                  ])
                : this.options

            const filteredOptions = options.filter((option) => {
                return option.label.toLowerCase().includes(this.filter.toLowerCase())
            })

            return filteredOptions
        }
    },

    created() {
        if (this.selected) this.value = this.selected
    },

    methods: {
        toggle() {
            this.open = !this.open
        },

        close() {
            this.open = false
        },

        handleClick() {
            if (this.disabled) return

            this.toggle()

            if (this.filterable && this.open) {
                this.$nextTick(() => this.$refs.filter.focus())
            }
        },

        handleClickOutside() {
            this.close()
        },

        handleClickOption(option) {
            if (!option.footerButton) {
                this.value = option.label
            }

            this.close()
            this.$emit('select', option)
        }
    }
}
</script>

<style lang="scss">
.select-component {
    position: relative;

    width: 300px;
}

.select-component__button {
    display: flex;
    align-items: center;

    width: 100%;

    color: desaturate(lighten(#34495e, 30), 20);

    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    white-space: nowrap;

    margin: 0;
    padding: 14px 44px 14px 18px;

    background-color: #ffffff;
    border: 0;
    border-radius: 6px;
    outline: 0;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);

    user-select: none;

    transition: all 0.2s ease;

    cursor: pointer;

    &:not(.is-disabled):hover,
    &.is-open {
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }

    &.is-selected {
        color: #34495e;
    }

    &.is-disabled {
        opacity: 0.6;

        cursor: not-allowed;
    }
}

.select-component__icon {
    @include mask-icon('~@/assets/svg/icons/chevron-down.svg');

    position: absolute;

    top: 0;
    bottom: 0;
    right: 0;

    width: 16px;
    height: 100%;

    margin: 0 14px;

    background-color: desaturate(lighten(#2c3e50, 30), 20);
}

.select-component__options {
    position: absolute;

    z-index: z-index('above');

    left: -1px;
    right: -1px;

    max-height: 178px;

    list-style-type: none;

    padding: 0;
    margin: 3px 0 0;

    background-color: #fff;
    border: 1px solid #f1f1f1;
    border-radius: 6px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

    overflow-y: scroll;
}

.select-component__filter {
    display: flex;
    align-items: center;

    color: #34495e;

    padding: 8px 18px;

    &:not(:only-child) {
        border-bottom: 1px solid #f3f3f3;
    }

    svg {
        color: #808b95;

        flex-shrink: 0;

        margin-right: 8px;
    }
}

.select-component__filter__inner {
    width: 100%;

    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    white-space: nowrap;
}

.select-component__option {
    cursor: pointer;

    &:not(:last-child) {
        border-bottom: 1px solid #f3f3f3;
    }

    &:hover {
        background-color: #f9f9f9;
    }

    &.is-active {
        background-color: #f6f6f6;
    }
}

.select-component__option__inner {
    display: flex;
    align-items: center;

    color: #34495e;

    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    white-space: nowrap;

    padding: 14px 18px;

    user-select: none;
}

.select-component-enter,
.select-component-leave-to {
    opacity: 0;

    transform: translateY(-5px);
}

.select-component-enter-active,
.select-component-leave-active {
    transition: opacity 0.2s ease, transform 0.1s ease;
}
</style>
