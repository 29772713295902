<template>
    <div
        :class="[
            'trash-component',
            {
                'is-hover': isHover
            }
        ]"
    >
        <ButtonComponent
            class="trash-component__button"
            type="danger"
            shadow
            @mouseenter="isHover = true"
            @mouseleave="isHover = false"
        >
            <i class="trash-component__icon" />
            <transition :css="false" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                <span v-if="isHover">
                    <span>
                        <slot>{{ label }}</slot>
                    </span>
                </span>
            </transition>
        </ButtonComponent>
    </div>
</template>

<script>
import Velocity from 'velocity-animate'

export default {
    name: 'TrashComponent',

    props: {
        label: {
            type: String,
            default: 'Trash'
        }
    },

    data() {
        return {
            isHover: false
        }
    },

    methods: {
        beforeEnter(element) {
            element.style.opacity = 0
            element.style.width = 0
        },

        enter(element) {
            const width = element.scrollWidth

            Velocity(element, { opacity: 1, width }, { duration: 300 })
        },

        leave(element, done) {
            Velocity(element, { opacity: 0, width: 0 }, { duration: 300, complete: done })
        }
    }
}
</script>

<style lang="scss">
.trash-component {
}

.trash-component__button {
    background-color: transparent;

    transition: all 0.3s ease;

    &:not(.is-disabled):hover {
        background-color: #f75851;

        transform: translateY(0) !important;
    }

    > span {
        will-change: opacity, width;

        > span {
            padding-left: 8px;
        }
    }
}

.trash-component__icon {
    @include mask-icon('~@/assets/svg/icons/trash.svg');

    display: inline-block;

    width: 16px;
    height: 16px;

    margin-top: -2px;

    background-color: #f75851;

    transition: all 0.3s ease;

    :not(.is-disabled):hover > & {
        background-color: #fff;
    }
}
</style>
