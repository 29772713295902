import IntegrationService from '@/services/integration-service'

const state = () => ({
    accounts: [],
    integrations: []
})

const getters = {
    accounts: (state) => state.accounts,
    integrations: (state) => state.integrations
}

const actions = {
    async fetchAccounts({ commit }) {
        const { data } = await IntegrationService.listAccounts()

        commit('setAccounts', { accounts: data })
    },

    async addAccount({ commit }, payload) {
        const { data } = await IntegrationService.addAccount(payload)

        commit('addAccount', { account: data })

        return data
    },

    async fetchIntegrations({ commit }, payload) {
        const { data } = await IntegrationService.listIntegrations(payload)

        commit('setIntegrations', { integrations: data })
    },

    async addIntegration({ commit }, payload) {
        const { data } = await IntegrationService.addIntegration(payload)

        data.type = payload.type

        commit('addIntegration', { integration: data })
    },

    async updateIntegration({ commit }, payload) {
        const { data } = await IntegrationService.updateIntegration(payload)

        commit('updateIntegration', { integration: data })
    },

    async deleteIntegration({ commit }, payload) {
        await IntegrationService.deleteIntegration(payload)

        commit('deleteIntegration', { integration: payload })
    }
}

const mutations = {
    setAccounts(state, { accounts }) {
        state.accounts = accounts
    },

    addAccount(state, { account }) {
        state.accounts.push(account)
    },

    setIntegrations(state, { integrations }) {
        state.integrations = integrations
    },

    addIntegration(state, { integration }) {
        state.integrations.push(integration)
    },

    updateIntegration(state, { integration }) {
        const index = state.integrations.findIndex(
            (stateIntegration) => stateIntegration.integration_id === integration.integration_id
        )
        const data = state.integrations[index]

        state.integrations.splice(index, 1, {
            ...data,
            active: integration.active
        })
    },

    deleteIntegration(state, { integration }) {
        const index = state.integrations.findIndex((settings) => {
            return settings.integration_id === integration.integration_id
        })

        state.integrations.splice(index, 1)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
