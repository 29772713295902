<template>
    <div v-click-outside="onClickOutside" class="popover-component">
        <div ref="trigger" class="popover-component__trigger">
            <slot name="trigger" />
        </div>
        <transition name="slide-fade">
            <div
                v-if="opened"
                ref="container"
                v-escape="onEscape"
                class="popover-component__container"
                :style="style"
            >
                <div v-if="$slots.header || header" class="popover-component__header">
                    <slot name="header">{{ header }}</slot>
                </div>
                <div v-if="$slots.default" class="popover-component__content">
                    <slot />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import clickOutside from '@/directives/clickOutside'
import escape from '@/directives/escape'

export default {
    name: 'PopoverComponent',

    directives: {
        clickOutside,
        escape
    },

    props: {
        visible: {
            type: Boolean,
            default: false
        },

        header: {
            type: String,
            default: ''
        },

        width: {
            type: String,
            default: '150px'
        },

        placement: {
            type: String,
            default: 'bottom',
            validator(value) {
                return [
                    'top',
                    'top-start',
                    'top-end',
                    'bottom',
                    'bottom-start',
                    'bottom-end',
                    'left',
                    'left-start',
                    'left-end',
                    'right',
                    'right-start',
                    'right-end'
                ].includes(value)
            }
        },

        offset: {
            type: Number,
            default: 12
        },

        closeDelay: {
            type: Number,
            default: 200
        }
    },

    data() {
        return {
            opened: false,
            position: {
                left: 0,
                top: 0
            },
            closeTimer: null
        }
    },

    computed: {
        style() {
            let style = { ...this.position }

            style.width = this.width

            return style
        }
    },

    watch: {
        visible(value) {
            value ? this.open() : this.close()
        }
    },

    methods: {
        onClickOutside() {
            if (this.opened) {
                this.close()
            }
        },

        onEscape() {
            if (this.opened) {
                this.close()
            }
        },

        getBasePlacement(placement) {
            return placement.split('-')[0]
        },

        getVariation(placement) {
            return placement.split('-')[1]
        },

        getMainAxis(placement) {
            return ['top', 'bottom'].indexOf(placement) >= 0 ? 'left' : 'top'
        },

        getPosition(trigger, container, placement) {
            const basePlacement = this.getBasePlacement(placement)
            const variation = this.getVariation(placement)
            const centerX = 0.5 * (trigger.offsetWidth - container.offsetWidth)
            const centerY = 0.5 * (trigger.offsetHeight - container.offsetHeight)

            let offsets

            switch (basePlacement) {
                case 'top':
                    offsets = {
                        left: centerX,
                        top: -container.offsetHeight - this.offset
                    }
                    break
                case 'bottom':
                    offsets = {
                        left: centerX,
                        top: this.offset + trigger.offsetHeight
                    }
                    break
                case 'left':
                    offsets = {
                        left: -container.offsetWidth - this.offset,
                        top: centerY
                    }
                    break
                case 'right':
                    offsets = {
                        left: this.offset + trigger.offsetWidth,
                        top: centerY
                    }
                    break
            }

            const mainAxis = this.getMainAxis(basePlacement)
            const offset = mainAxis === 'top' ? 'offsetHeight' : 'offsetWidth'

            switch (variation) {
                case 'start':
                    offsets[mainAxis] =
                        Math.floor(offsets[mainAxis]) -
                        Math.floor(0.5 * (trigger[offset] - container[offset]))
                    break
                case 'end':
                    offsets[mainAxis] =
                        Math.floor(offsets[mainAxis]) +
                        Math.ceil(0.5 * (trigger[offset] - container[offset]))
                    break
            }

            return offsets
        },

        open() {
            if (this.closeTimer) {
                clearTimeout(this.closeTimer)
                this.closeTimer = null
            }

            this.opened = true

            this.$nextTick(() => {
                const position = this.getPosition(
                    this.$refs.trigger,
                    this.$refs.container,
                    this.placement
                )

                this.position = {
                    left: `${position.left}px`,
                    top: `${position.top}px`
                }
            })
        },

        close() {
            clearTimeout(this.closeTimer)

            if (this.closeDelay > 0) {
                this.closeTimer = setTimeout(() => {
                    this.opened = false
                    this.$emit('update:visible', false)
                }, this.closeDelay)
            } else {
                this.opened = false
                this.$emit('update:visible', false)
            }
        }
    }
}
</script>

<style lang="scss">
.popover-component {
    position: relative;
}

.popover-component__trigger {
    display: flex;
}

.popover-component__container {
    position: absolute;
    z-index: z-index('toolbar');

    box-sizing: border-box;

    font-size: 14px;
    line-height: 1.5;
    white-space: pre-wrap;

    padding: 18px 20px;

    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.16), 0 0 1px 0 rgba(0, 0, 0, 0.1);

    will-change: opacity, transform;
}

.popover-component__header {
    color: #34495e;

    font-weight: 500;

    margin-bottom: 15px;

    &:only-child {
        margin: 0;
    }
}

.popover-component__content {
    color: #2c3e50;

    font-weight: 400;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: opacity 0.25s cubic-bezier(0, 1, 0.4, 1),
        transform 0.25s cubic-bezier(0.18, 1.25, 0.4, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
    transform: scale(0.85);

    opacity: 0;
}

.slide-fade-enter-to {
    transform: scale(1);

    opacity: 1;
}
</style>
