<template>
    <section :class="['container-component', `container-component--${direction}`]">
        <slot />
    </section>
</template>

<script>
export default {
    name: 'ContainerComponent',

    props: {
        direction: {
            type: String,
            default: 'horizontal',
            validator(value) {
                return ['horizontal', 'vertical'].includes(value)
            }
        }
    }
}
</script>

<style lang="scss">
.container-component {
    display: flex;

    flex: 1;
    flex-basis: auto;

    &.container-component--horizontal {
        flex-direction: row;
    }

    &.container-component--vertical {
        flex-direction: column;
    }
}
</style>
