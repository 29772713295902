<template>
    <li
        :class="[
            'menu-item-component',
            {
                'is-active': active,
                'is-disabled': disabled
            }
        ]"
        :style="style"
        @click="onClick"
    >
        <div class="menu-item-component__title">
            <slot />
        </div>
    </li>
</template>

<script>
export default {
    name: 'MenuItemComponent',

    inject: ['menu'],

    props: {
        index: {
            default: null,
            validator: (value) => typeof value === 'string' || value === null
        },

        route: {
            type: [String, Object],
            default: ''
        },

        disabled: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        active() {
            return this.index === this.menu.activeIndex
        },

        textColor() {
            return this.menu.textColor || ''
        },

        activeTextColor() {
            return this.menu.activeTextColor || ''
        },

        style() {
            const style = {}

            style.color = this.active ? this.activeTextColor : this.textColor

            return style
        }
    },

    methods: {
        onClick() {
            if (!this.disabled) {
                this.menu.emitter.emit('item-click', this)
                this.$emit('click', this)
            }
        }
    }
}
</script>

<style lang="scss">
.menu-item-component {
    position: relative;

    display: flex;
    align-items: center;

    color: #34495e;

    font-size: 15px;
    font-weight: 500;

    white-space: nowrap;

    padding: 10px 20px;

    cursor: pointer;

    transition: color 0.1s ease, background-color 0.1s ease, opacity 0.1s ease;

    &:hover:not(:focus):not(.is-active):not(.is-disabled) {
        opacity: 0.6;
    }

    &:focus,
    &.is-active {
        color: #de524b;
    }

    &.is-disabled {
        cursor: not-allowed;
    }
}

.menu-item-component__title {
    display: flex;
    align-items: center;

    width: 100%;
    height: 100%;

    .is-disabled & {
        opacity: 0.25;
    }
}
</style>
