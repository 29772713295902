function mounted(element, binding) {
    if (binding.value !== undefined && !binding.value) {
        return
    }

    element.focus()
}

const directive = {
    mounted
}

export default directive
