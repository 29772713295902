<template>
    <div
        class="step-component"
        :class="[
            'step-component',
            `is-${currentStatus}`,
            { 'is-vertical': isVertical, 'has-connect': hasConnect }
        ]"
    >
        <div class="step-component__inner">
            <span v-if="currentStatus !== $parent.finishStatus && currentStatus !== 'error'">{{
                index + 1
            }}</span>
            <template v-else>
                <svg
                    v-if="currentStatus === $parent.finishStatus"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="transparent"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="step-component__icon step-component__icon--check"
                >
                    <path d="M20 6L9 17l-5-5" />
                </svg>
                <svg v-else />
            </template>
        </div>
        <div class="step-component__title">
            <slot name="title">{{ title }}</slot>
        </div>
        <div class="step-component__line">
            <div class="step-component__line__inner" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'StepComponent',

    props: {
        title: {
            type: String,
            default: ''
        },

        status: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            index: -1,
            internalStatus: ''
        }
    },

    computed: {
        currentStatus() {
            return this.status || this.internalStatus
        },

        prevStatus() {
            const prevStep = this.$parent.steps[this.index - 1]
            return prevStep ? prevStep.currentStatus : 'wait'
        },

        isVertical() {
            return this.$parent.direction === 'vertical'
        },

        hasConnect() {
            return this.$parent.active > this.index + 1
        }
    },

    beforeCreate() {
        this.$parent.steps.push(this)
    },

    beforeUnmount() {
        const steps = this.$parent.steps
        const index = steps.indexOf(this)

        if (index >= 0) {
            steps.splice(index, 1)
        }
    },

    mounted() {
        const unwatch = this.$watch('index', () => {
            this.$watch('$parent.active', this.updateStatus, { immediate: true })
            this.$watch(
                '$parent.processStatus',
                () => {
                    const activeIndex = this.$parent.active

                    this.updateStatus(activeIndex)
                },
                { immediate: true }
            )

            unwatch()
        })
    },

    methods: {
        updateStatus(value) {
            if (value > this.index) {
                this.internalStatus = this.$parent.finishStatus
            } else if (value === this.index && this.prevStatus !== 'error') {
                this.internalStatus = this.$parent.processStatus
            } else {
                this.internalStatus = 'wait'
            }
        }
    }
}
</script>

<style lang="scss">
.step-component {
    display: flex;
    align-items: center;
    flex-basis: 50%;

    color: #7f8c8d;

    margin-right: 10px;

    &:last-of-type {
        flex-basis: auto;

        margin-right: 0;

        .step-component__title {
            margin-right: 0;
        }
    }

    &.is-vertical {
    }
}

.step-component__inner {
    position: relative;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    font-size: 15px;
    font-weight: 500;

    width: 32px;
    height: 32px;

    background-color: lighten(#eaeaea, 4);

    border-radius: 50%;

    .is-process & {
        color: #ffffff;

        font-weight: 600;
    }

    .is-process & {
        background-color: #ffba59;
    }

    .is-finish & {
        background-color: transparent;
        border: 2px solid #2ecc71;

        animation: scale 0.3s ease-in-out 0.3s both;

        @keyframes scale {
            0%,
            100% {
                transform: none;
            }

            50% {
                transform: scale3d(1.1, 1.1, 1);
            }
        }
    }
}

.step-component__icon {
    display: inline-block;

    width: 16px;
    height: 16px;

    background-size: contain;
}

.step-component__icon--check {
    stroke: #2ecc71;
    stroke-dasharray: 24;
    stroke-dashoffset: 24;

    animation: stroke 0.3s ease 0.2s forwards;

    @keyframes stroke {
        to {
            stroke-dashoffset: 0;
        }
    }
}

.step-component__title {
    display: inline-flex;
    align-items: center;

    font-size: 14px;

    margin: 0 10px;

    .is-process &,
    .is-finish & {
        color: #2c3e50;

        font-weight: 500;
    }
}

.step-component__line {
    display: inline-flex;
    flex-grow: 1;

    height: 2px;

    background-color: lighten(#eaeaea, 4);

    border-radius: 2px;
}

.step-component__line__inner {
    width: 0%;
    height: inherit;

    background-color: #2ecc71;

    border-radius: inherit;

    transition: width 0.15s ease-out;

    .has-connect & {
        width: 100%;
    }
}
</style>
