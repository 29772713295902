<template>
    <div class="breadcrumb-component">
        <slot />
    </div>
</template>

<script>
import mitt from 'mitt'

export default {
    name: 'BreadcrumbComponent',

    provide() {
        return {
            breadcrumb: this
        }
    },

    props: {
        defaultActive: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            emitter: mitt(),
            activeRoute: this.defaultActive
        }
    },

    watch: {
        defaultActive(value) {
            this.activeRoute = value
        }
    },

    created() {
        this.emitter.on('item-click', this.handleItemClick)
    },

    beforeUnmount() {
        this.emitter.off('item-click')
    },

    methods: {
        handleItemClick(item) {
            if (item.to) {
                if (this.activeRoute !== item.to) {
                    this.activeRoute = item.to

                    this.$emit('select', item.to, item)

                    if (this.$router) {
                        item.replace ? this.$router.replace(item.to) : this.$router.push(item.to)
                    }
                }
            }
        }
    }
}
</script>

<style lang="scss">
.breadcrumb-component {
    display: flex;
    justify-content: space-between;

    width: 100%;
}
</style>
