import { api } from './api'

export default {
    getSurvey(payload) {
        return api.get(`/surveys/${payload.id}`)
    },

    getSurveyItems(payload) {
        return api.get(`/surveys/${payload.id}/items`)
    },

    getShortLink(payload) {
        return api.get(`/shorten/${payload.id}`)
    }
}
