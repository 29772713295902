<template>
    <div
        :class="[
            'stepper-component',
            { 'is-disabled': disabled },
            { 'has-no-controls': !controls },
            { 'has-controls-right': controlsAtRight }
        ]"
        @dragstart.prevent
    >
        <button
            v-if="controls"
            :class="['stepper-component__button--increase', { 'is-disabled': maxDisabled }]"
            @click="increaseValue"
            @mousedown="handleMouseDown('increase')"
            @mouseleave="handleMouseUp"
            @mouseup="handleMouseUp"
        >
            <i
                :class="`stepper-component__button__icon--${controlsAtRight ? 'arrow-up' : 'plus'}`"
            />
        </button>
        <button
            v-if="controls"
            :class="['stepper-component__button--decrease', { 'is-disabled': minDisabled }]"
            @click="decreaseValue"
            @mousedown="handleMouseDown('decrease')"
            @mouseleave="handleMouseUp"
            @mouseup="handleMouseUp"
        >
            <i
                :class="`stepper-component__button__icon--${
                    controlsAtRight ? 'arrow-down' : 'minus'
                }`"
            />
        </button>
        <div class="stepper-component__input">
            <input
                ref="input"
                class="stepper-component__input__inner"
                :placeholder="placeholder"
                :spellcheck="spellcheck"
                :disabled="disabled"
                :min="min"
                :max="max"
                :value="displayValue"
                @focus="handleFocus"
                @blur="handleBlur"
                @input="handleInput($event.target.value)"
                @change="handleChange($event.target.value)"
                @keydown.up.prevent="increaseValue"
                @keydown.down.prevent="decreaseValue"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'StepperComponent',

    props: {
        value: {
            type: [String, Number],
            default: ''
        },

        placeholder: {
            type: String,
            default: ''
        },

        spellcheck: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        },

        step: {
            type: Number,
            default: 1
        },

        max: {
            type: Number,
            default: Infinity
        },

        min: {
            type: Number,
            default: 1
        },

        controls: {
            type: Boolean,
            default: true
        },

        controlsPosition: {
            type: String,
            default: 'default',
            validator(value) {
                return ['default', 'right'].includes(value)
            }
        }
    },

    data() {
        return {
            currentValue: 0,
            userInput: null,
            repeatTimeout: false,
            repeatInterval: false
        }
    },

    computed: {
        displayValue() {
            if (this.userInput !== null) {
                return this.userInput
            }

            return this.currentValue
        },

        minDisabled() {
            return this.getDecrease(this.value, this.step) < this.min
        },

        maxDisabled() {
            return this.getIncrease(this.value, this.step) > this.max
        },

        controlsAtRight() {
            return this.controls && this.controlsPosition === 'right'
        }
    },

    watch: {
        value: {
            immediate: true,
            handler(value) {
                let newValue = value === undefined ? value : Number(value)

                if (newValue !== undefined) {
                    if (isNaN(newValue)) {
                        return
                    }

                    newValue = this.toPrecision(newValue)
                }

                if (newValue >= this.max) newValue = this.max
                if (newValue <= this.min) newValue = this.min

                this.currentValue = newValue
                this.userInput = null
                this.$emit('input', newValue)
            }
        }
    },

    methods: {
        handleMouseDown(additive) {
            if (!this.repeatTimeout) {
                this.repeatTimeout = setTimeout(() => {
                    if (!this.repeatInterval) {
                        this.repeatInterval = setInterval(() => {
                            if (additive === 'increase') {
                                this.increaseValue()
                            }

                            if (additive === 'decrease') {
                                this.decreaseValue()
                            }
                        }, 100)
                    }
                }, 200)
            }
        },

        handleMouseUp() {
            clearTimeout(this.repeatTimeout)
            clearInterval(this.repeatInterval)

            this.repeatTimeout = false
            this.repeatInterval = false
        },

        handleFocus(event) {
            this.$emit('focus', event)
        },

        handleBlur(event) {
            this.$emit('blur', event)
        },

        handleInput(value) {
            this.userInput = value
        },

        handleChange(value) {
            const number = value === '' ? undefined : Number(value)

            if (!isNaN(number) || value === '') {
                this.setCurrentValue(number)
            }

            this.userInput = null
        },

        toPrecision(value) {
            if (value === undefined) return 0

            return parseFloat(Math.round(value))
        },

        setCurrentValue(value) {
            const currentValue = this.currentValue

            value = this.toPrecision(value)

            if (value >= this.max) value = this.max
            if (value <= this.min) value = this.min
            if (currentValue === value) return

            this.userInput = null
            this.$emit('input', value)
            this.$emit('change', value, currentValue)
            this.currentValue = value
        },

        getIncrease(value, step) {
            if (typeof value !== 'number' && value !== undefined) return this.currentValue

            return parseFloat(Math.round(value + step))
        },

        getDecrease(value, step) {
            if (typeof value !== 'number' && value !== undefined) return this.currentValue

            return parseFloat(Math.round(value - step))
        },

        increaseValue() {
            if (this.disabled || this.maxDisabled) return

            const value = this.value || 0

            this.setCurrentValue(this.getIncrease(value, this.step))
        },

        decreaseValue() {
            if (this.disabled || this.minDisabled) return

            const value = this.value || 0

            this.setCurrentValue(this.getDecrease(value, this.step))
        }
    }
}
</script>

<style scoped lang="scss">
.stepper-component {
    position: relative;

    width: 180px;

    background-color: #f3f3f3;

    border-radius: 3px;
}

.stepper-component__button {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;

    z-index: z-index('above');

    top: 2px;
    bottom: 2px;

    width: 36px;

    padding: 0;
    margin: 0;

    background-color: #fff;
    border: 0;
    border-radius: 4px;
    outline: 0;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);

    transition: all 0.2s ease;

    user-select: none;

    cursor: pointer;

    &:not(.is-disabled):hover {
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

        transform: translateY(-1px);
    }

    &.is-disabled {
        cursor: not-allowed;
    }

    .has-no-controls & {
        display: none;
    }

    .has-controls-right & {
        height: 18px;
    }
}

%stepper-component__button__icon {
    width: 16px;
    height: 16px;

    margin-top: -2px;

    background-size: contain;

    transition: opacity 0.2s ease;

    .is-disabled & {
        opacity: 0.3;
    }
}

.stepper-component__button__icon--plus {
    @extend %stepper-component__button__icon;
    @include icon('~@/assets/svg/icons/plus.svg');
}

.stepper-component__button__icon--minus {
    @extend %stepper-component__button__icon;
    @include icon('~@/assets/svg/icons/minus.svg');
}

.stepper-component__button--increase {
    @extend .stepper-component__button;

    right: 2px;

    .has-controls-right & {
    }
}

.stepper-component__button--decrease {
    @extend .stepper-component__button;

    left: 2px;

    .has-controls-right & {
        top: auto;
        left: auto;
        right: 2px;
        bottom: 2px;
    }
}

.stepper-component__input {
    display: flex;
    flex-direction: row;
}

.stepper-component__input__inner {
    width: 100%;
    min-height: 40px;

    color: #2c3e50;

    font-size: 14px;
    text-align: center;

    padding: 0 52px;
    margin: 0;

    background-color: transparent;
    border: 0;
    outline: 0;

    box-sizing: border-box;

    .has-no-controls & {
        padding: 0 12px;
    }

    .has-controls-right & {
        padding: 0 46px 0 12px;
    }
}
</style>
