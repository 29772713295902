<template>
    <div :class="['steps-component', { 'steps-component--vertical': direction === 'vertical' }]">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'StepsComponent',

    props: {
        active: {
            type: Number,
            default: 0
        },

        direction: {
            type: String,
            default: 'horizontal',
            validator(value) {
                return ['vertical', 'horizontal'].includes(value)
            }
        },

        finishStatus: {
            type: String,
            default: 'finish'
        },

        processStatus: {
            type: String,
            default: 'process'
        }
    },

    data() {
        return {
            steps: []
        }
    },

    watch: {
        active(newValue, oldValue) {
            this.$emit('change', newValue, oldValue)
        },

        steps(steps) {
            steps.forEach((step, index) => {
                step.index = index
            })
        }
    }
}
</script>

<style lang="scss">
.steps-component {
    display: flex;
    flex-grow: 1;
}

.steps-component--vertical {
}
</style>
