import { api } from './api'

export default {
    getUser() {
        return api.get('/users/me')
    },

    getFirebaseJwt() {
        return api.get('/users/me/jwt')
    },

    getPermissions() {
        return api.get('/users/me/permissions')
    },

    getPermissionLevel(payload) {
        return api.get(`/internal/level/${payload.permission}?count=${payload.count}`)
    }
}
