import AuthService from '@/services/auth-service'

const state = () => ({
    user: {},
    permissions: {}
})

const getters = {
    user: (state) => state.user,
    encodedUserId: (state) => btoa(state.user.provider + (state.user.ID || state.user.uid)),
    permissions: (state) => state.permissions
}

const actions = {
    async getUser({ commit }) {
        const { data } = await AuthService.getUser()

        commit('setUser', { user: data })
    },

    async getPermissions({ commit }) {
        const { data } = await AuthService.getPermissions()

        commit('setUserPermission', { permissions: data })
    }
}

const mutations = {
    setUser(state, { user }) {
        state.user = user
    },

    setUserPermission(state, { permissions }) {
        state.permissions = permissions
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
