// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/svg/icons/chevron-down.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/svg/icons/chevron-up.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".expand-component .card-component__header{padding:18px 20px}.expand-component .card-component__content,.expand-component .card-component__footer{margin-top:-1px;padding:0}.expand-component__container{overflow:hidden;will-change:opacity,height}.expand-component__inner{padding:20px 20px 21px}.expand-component__footer{padding:12px 20px;cursor:pointer}.expand-component__icon{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat;display:inline-block;width:20px;height:20px;-webkit-transition:all .2s ease;transition:all .2s ease}:hover>.expand-component__icon{-webkit-transform:translateY(1px);transform:translateY(1px)}.expand-component__icon.is-expanded{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-position:50%;background-repeat:no-repeat}:hover>.expand-component__icon.is-expanded{-webkit-transform:translateY(-1px);transform:translateY(-1px)}", ""]);
// Exports
module.exports = exports;
