<template>
    <span :class="['tag-component', `tag-component--${type}`]">
        <slot />
    </span>
</template>

<script>
export default {
    name: 'TagComponent',

    props: {
        type: {
            type: String,
            default: 'default',
            validator(value) {
                return ['default', 'primary', 'success', 'info', 'warning', 'danger'].includes(
                    value
                )
            }
        }
    }
}
</script>

<style scoped lang="scss">
.tag-component {
    @include font-fira-code;

    display: inline-block;

    color: #34495e;

    font-size: 12px;
    font-weight: 500;
    line-height: 1;

    padding: 3px 8px;

    background-color: #eaeaea;
    border-radius: 6px;
}

.tag-component--primary {
    color: #fff;

    background-color: lighten(#59a8ff, 8);
}

.tag-component--success {
    color: #fff;

    background-color: lighten(#2ecc71, 8);
}

.tag-component--warning {
    color: #fff;

    background-color: lighten(#ffba59, 8);
}

.tag-component--danger {
    color: #fff;

    background-color: lighten(#f75851, 8);
}
</style>
