<template>
    <div class="radio-group-component">
        <slot />
    </div>
</template>

<script>
import mitt from 'mitt'

export default {
    name: 'RadioGroupComponent',

    emits: ['update:modelValue'],

    provide() {
        return {
            radioGroup: this
        }
    },

    data() {
        return {
            emitter: mitt()
        }
    },

    props: {
        modelValue: {
            type: [String, Number],
            default: ''
        },

        disabled: {
            type: Boolean,
            default: false
        }
    },

    created() {
        this.emitter.on('handleChange', (value) => {
            this.$emit('update:modelValue', value)
        })
    }
}
</script>

<style lang="scss">
.radio-group-component {
    display: flex;
}
</style>
