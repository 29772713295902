<template>
    <div class="upgrade-component">
        <ModalComponent ref="modal" width="800px" @closed="cancel">
            <template #header>
                <transition name="fade-in">
                    <div
                        v-if="subscriptionLevel"
                        :class="`upgrade-component__subscription--${subscriptionLevel}`"
                    >
                        <i class="upgrade-component__subscription__icon" />
                    </div>
                </transition>
                <ImageComponent :src="`/img/modal/upgrade/${image}`" fit="cover">
                    <template #placeholder>
                        <LoadingComponent stroke-color="#579ae6" />
                    </template>
                    <template #error>
                        <ImageComponent src="/img/modal/error.png" fit="cover" />
                    </template>
                </ImageComponent>
            </template>
            <div class="upgrade-component__content">
                <div class="upgrade-component__markdown" v-html="markdown" />
            </div>
            <template #footer>
                <div class="upgrade-component__actions">
                    <ButtonComponent @click="cancel"> Not right now </ButtonComponent>
                    <ButtonComponent v-if="freeUpgrade" type="info" @click="confirm('free')">
                        Get it for free
                    </ButtonComponent>
                    <ButtonComponent type="success" shadow @click="confirm">
                        <i class="upgrade-component__chevrons-up" />
                        <i class="upgrade-component__chevrons-up" />
                        <span>Upgrade now</span>
                    </ButtonComponent>
                </div>
            </template>
        </ModalComponent>
    </div>
</template>

<script>
import AuthService from '@/services/auth-service'
import { mapGetters } from 'vuex'

export default {
    name: 'UpgradeComponent',

    props: {
        name: {
            type: String,
            required: true
        },

        permission: {
            type: String,
            default: (props) => props.name
        },

        image: {
            type: String,
            default: 'warning.png'
        },

        freeUpgrade: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            markdown: '',
            value: '',
            resolve: '',
            subscriptionLevel: ''
        }
    },

    computed: {
        ...mapGetters({
            permissions: 'auth/permissions'
        }),

        markdownLoader() {
            return () =>
                this.name &&
                import(`@/assets/markdown/upgrade/${this.name}`).then((content) => {
                    return content.default
                })
        }
    },

    async created() {
        const fetchedMarkdown = await this.markdownLoader()

        this.markdown = fetchedMarkdown
    },

    methods: {
        async fetchPermissionLevel(permission) {
            const { data } = await AuthService.getPermissionLevel({
                permission,
                count: this.permissions[permission] + 1
            })

            this.subscriptionLevel = data
        },

        track(action) {
            this.$gtag.event(action, {
                event_category: this.permission,
                event_label: this.permissions.level
            })
        },

        open() {
            this.fetchPermissionLevel(this.permission)

            this.$refs.modal.open()

            return new Promise((resolve) => {
                this.resolve = resolve
            })
        },

        close() {
            this.$refs.modal.close()
            this.value = ''
        },

        confirm(type) {
            if (type) {
                this.track(`confirm-${type}-upgrade-modal`)
                this.resolve(type)
            } else {
                this.track('confirm-upgrade-modal')
                this.resolve(true)
            }
        },

        cancel() {
            this.track('cancel-upgrade-modal')
            this.resolve(false)
        }
    }
}
</script>

<style lang="scss">
.upgrade-component {
    .modal-component__container {
        background-color: #2c3e50;
    }

    .modal-component__header {
        height: auto;
        min-height: 300px;

        padding: 0;
    }

    .modal-component__body {
        display: flex;

        min-height: auto;

        overflow: hidden;
    }

    .modal-component__footer {
        justify-content: flex-end;

        padding: 30px;

        background-color: #34495e;
        border: none;
        border-radius: 0 0 6px 6px;
    }

    .image-component {
        z-index: 0;

        height: auto;
    }

    .image-component__inner {
        border-radius: 6px 6px 0 0;
    }

    .input-component {
        input {
            font-weight: 500;
        }
    }
}

.upgrade-component__subscription {
    position: absolute;
    top: 0;
    left: 0;
    z-index: z-index('above');

    width: 300px;
    height: 400px;

    border-radius: 6px;

    filter: drop-shadow(0 4px 20px rgba(0, 0, 0, 0.16)) drop-shadow(0 0 1px rgba(0, 0, 0, 0.1));
}

.upgrade-component__subscription--pro {
    @extend .upgrade-component__subscription;

    background-image: radial-gradient(
        circle at 100% 100%,
        transparent 0%,
        transparent 400px,
        rgba(#89d579, 0.6) 400px
    );
}

.upgrade-component__subscription--business {
    @extend .upgrade-component__subscription;

    background-image: radial-gradient(
        circle at 100% 100%,
        transparent 0%,
        transparent 400px,
        rgba(#ffac0f, 0.6) 400px
    );
}

.upgrade-component__subscription--legendary {
    @extend .upgrade-component__subscription;

    background-image: radial-gradient(
        circle at 100% 100%,
        transparent 0%,
        transparent 400px,
        rgba(#fa827d, 0.6) 400px
    );
}

.upgrade-component__subscription__icon {
    display: block;

    width: 130px;
    height: 130px;

    margin: 5px 0 0 5px;

    .upgrade-component__subscription--pro & {
        @include icon('~@/assets/svg/subscription-pro.svg');
    }

    .upgrade-component__subscription--business & {
        @include icon('~@/assets/svg/subscription-business.svg');
    }

    .upgrade-component__subscription--legendary & {
        @include icon('~@/assets/svg/subscription-legendary.svg');
    }
}

.upgrade-component__content {
    line-height: 1.5;

    padding: 20px 30px;

    h2 {
        color: #fff;

        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.5;

        margin-bottom: 15px;
        margin-top: 0 !important;
    }

    h3 {
        color: #94dbfc;

        font-size: 15px;
        font-weight: 500;

        margin-bottom: 15px;
        margin-top: 0 !important;
    }

    p {
        color: #fff;

        font-size: 14px;

        padding-bottom: 15px;
        margin: 0 !important;

        &:last-child {
            padding-bottom: 0;
        }
    }

    a {
        color: #94dbfc;

        text-decoration: none;
    }
}

.upgrade-component__actions {
    display: flex;

    justify-content: flex-end;

    .button-component--success {
        position: relative;

        padding: 14px 18px 14px 40px;
    }

    .button-component {
        margin-left: 15px;

        &:first-of-type {
            margin-left: 0;
        }
    }

    .button-component--info {
        color: #fff;
        background-color: #384f66 !important;

        &:not(.is-disabled):not(.is-loading):hover {
            background-color: #384f66 !important;
        }
    }

    .button-component--default {
        &:hover {
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        }
    }
}

.upgrade-component__chevrons-up {
    @include icon('~@/assets/svg/icons/chevrons-up.svg');

    position: absolute;

    left: 16px;

    width: 16px;
    height: 16px;

    margin-top: -2px;

    background-size: contain;

    animation: slide-fade 1.4s 0.2s linear infinite;

    @keyframes slide-fade {
        0% {
            opacity: 1;
            transform: translateY(0px) scale(1);
        }
        25% {
            opacity: 0;
            transform: translateY(-10px) scale(0.9);
        }
        26% {
            opacity: 0;
            transform: translateY(10px) scale(0.9);
        }
        55% {
            opacity: 1;
            transform: translateY(0px) scale(1);
        }
    }
}
</style>
