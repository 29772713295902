<template>
    <div class="card-component">
        <div v-if="$slots.header || header" class="card-component__header">
            <slot name="header">{{ header }}</slot>
        </div>
        <div v-if="$slots.default" class="card-component__content">
            <slot />
        </div>
        <div
            v-if="$slots.footer || footer"
            :class="[
                'card-component__footer',
                {
                    'has-content': $slots.default
                }
            ]"
        >
            <slot name="footer">{{ footer }}</slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CardComponent',

    props: {
        header: {
            type: String,
            default: ''
        },

        footer: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss">
.card-component {
    position: relative;

    background-color: #fff;
    box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
}

.card-component__header {
    color: #34495e;

    font-size: 16px;
    font-weight: 500;

    padding: 18px 20px;

    border-bottom: 1px solid #f3f3f3;

    &:only-child {
        border: none;
    }
}

.card-component__content {
    color: #2c3e50;

    font-size: 14px;

    padding: 20px;
}

.card-component__footer {
    color: #2c3e50;

    font-size: 14px;

    padding: 18px 20px;

    border-radius: 0 0 6px 6px;

    &:not(:only-child).has-content {
        border-top: 1px solid #f3f3f3;
    }
}
</style>
