<template>
    <div class="pagination-component">
        <ButtonComponent type="info" :disabled="isFirst" @click="handlePrev"
            ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            >
                <polyline points="15 18 9 12 15 6" />
            </svg>
        </ButtonComponent>
        <ButtonComponent
            type="info"
            v-for="item in total"
            :class="{ 'is-active': isCurrent(item) }"
            :key="item"
            @click="handleItemClick(item)"
            >{{ item }}</ButtonComponent
        >
        <ButtonComponent type="info" :disabled="isLast" @click="handleNext"
            ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            >
                <polyline points="9 18 15 12 9 6" />
            </svg>
        </ButtonComponent>
    </div>
</template>

<script>
export default {
    name: 'PaginationComponent',

    props: {
        total: {
            type: Number,
            default: 1
        }
    },

    data() {
        return {
            current: 0
        }
    },

    computed: {
        isFirst() {
            return this.current === 0
        },

        isLast() {
            return this.current === this.total - 1
        }
    },

    methods: {
        isCurrent(item) {
            return this.current === item - 1
        },

        handlePrev() {
            if (!this.isFirst) this.current--
        },

        handleNext() {
            if (!this.isLast) this.current++
        },

        handleItemClick(item) {
            this.current = item - 1
        }
    }
}
</script>

<style lang="scss">
.pagination-component {
    display: flex;

    border-radius: 6px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);

    .button-component {
        min-width: 30px;

        font-size: 15px;

        border-radius: 0;
        box-shadow: none;

        &.is-active {
            color: white;
        }

        &:first-child {
            border-right: 1px solid #f3f3f3;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;

            &:hover {
                border-color: #ffd670;
            }
        }

        &:last-child {
            border-left: 1px solid #f3f3f3;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;

            &:hover {
                border-color: #ffd670;
            }
        }
    }
}
</style>
