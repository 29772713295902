// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/svg/icons/chevron-down.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".menu-sub-component{list-style-type:none}.menu-sub-component__title{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:#34495e;font-size:15px;font-weight:500;padding:10px 20px;cursor:pointer}.menu-sub-component__title:hover:not(:focus):not(.is-active):not(.is-disabled){opacity:.6}.menu-sub-component__title.is-active,.menu-sub-component__title:focus{color:#de524b}.menu-sub-component__title.is-disabled{cursor:not-allowed}.menu-sub-component__icon{-webkit-mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");-webkit-mask-position:center;mask-position:center;-webkit-mask-repeat:no-repeat;mask-repeat:no-repeat;display:inline-block;width:16px;height:16px;margin-left:4px;-webkit-mask-size:contain;mask-size:contain;background-color:#34495e}", ""]);
// Exports
module.exports = exports;
