<template>
    <div v-click-outside="handleClickOutside" class="edit-component">
        <InputComponent
            ref="input"
            v-model="inputValue"
            :placeholder="placeholder"
            :readonly="!editing"
            @keyup.esc="handleSave"
            @keyup.enter="handleSave"
            @click="handleEdit"
        >
            <template #append>
                <ButtonComponent v-if="!editing" class="edit-component__button" shadow>
                    <i class="edit-component__icon edit-component__icon--edit" />
                    <span>{{ editText }}</span>
                </ButtonComponent>
                <ButtonComponent
                    v-else
                    type="success"
                    :disabled="!inputValue"
                    shadow
                    @click.stop="handleSave"
                >
                    <i class="edit-component__icon edit-component__icon--save" />
                    <span>{{ saveText }}</span>
                </ButtonComponent>
            </template>
        </InputComponent>
    </div>
</template>

<script>
import clickOutside from '@/directives/clickOutside'

export default {
    name: 'EditComponent',

    directives: {
        clickOutside
    },

    props: {
        value: {
            type: [String, Number],
            default: ''
        },

        placeholder: {
            type: String,
            default: ''
        },

        editText: {
            type: String,
            default: 'Edit'
        },

        saveText: {
            type: String,
            default: 'Save'
        }
    },

    data() {
        return {
            editing: false
        }
    },

    computed: {
        inputValue: {
            get() {
                return this.value
            },
            set(inputValue) {
                this.$emit('input', inputValue)
            }
        }
    },

    methods: {
        handleClickOutside() {
            if (this.editing) this.handleSave()
        },

        handleEdit() {
            this.editing = true
            this.$refs.input.$refs.input.focus()
        },

        handleSave() {
            if (this.inputValue) {
                this.editing = false
                this.$emit('save', this.inputValue)
            }
        }
    }
}
</script>

<style scoped lang="scss">
.edit-component {
    width: 100%;

    .button-component {
        width: 90px;

        padding: 12px 16px 12px 14px;
    }
}

.edit-component__button {
    color: #34495e;

    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1) !important;

    &:not(.is-disabled):hover {
        background-color: #fff;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
    }
}

.edit-component__icon {
    display: inline-block;

    width: 16px;
    height: 16px;

    margin: -2px 8px 0 0;

    background-size: contain;
}

.edit-component__icon--edit {
    @include icon('~@/assets/svg/icons/edit.svg');
}

.edit-component__icon--save {
    @include icon('~@/assets/svg/icons/save.svg');
}
</style>
