// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/svg/icons/trash.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".trash-component__button{background-color:transparent;-webkit-transition:all .3s ease;transition:all .3s ease}.trash-component__button:not(.is-disabled):hover{background-color:#f75851;-webkit-transform:translateY(0)!important;transform:translateY(0)!important}.trash-component__button>span{will-change:opacity,width}.trash-component__button>span>span{padding-left:8px}.trash-component__icon{-webkit-mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");-webkit-mask-position:center;mask-position:center;-webkit-mask-repeat:no-repeat;mask-repeat:no-repeat;display:inline-block;width:16px;height:16px;margin-top:-2px;background-color:#f75851;-webkit-transition:all .3s ease;transition:all .3s ease}:not(.is-disabled):hover>.trash-component__icon{background-color:#fff}", ""]);
// Exports
module.exports = exports;
