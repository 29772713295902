<template>
    <div
        v-click-outside="handleClickOutside"
        :class="['dropdown-component', { 'is-visible': visible }]"
    >
        <slot />
        <transition name="slide-fade">
            <ul
                v-show="visible"
                ref="dropdown"
                v-escape="handleEscape"
                :class="['dropdown-component__menu', `-${placement}`]"
                :style="style"
            >
                <slot name="dropdown" />
            </ul>
        </transition>
    </div>
</template>

<script>
import mitt from 'mitt'
import '@/directives/clickOutside'
import '@/directives/escape'

export default {
    name: 'DropdownComponent',

    provide() {
        return {
            dropdown: this
        }
    },

    props: {
        isOpen: {
            type: Boolean,
            default: false
        },

        trigger: {
            type: String,
            default: 'click',
            validator(value) {
                return ['click', 'hover'].indexOf(value) !== -1
            }
        },

        minWidth: {
            type: String,
            default: '224px'
        },

        placement: {
            type: String,
            default: 'bottom',
            validator(value) {
                return (
                    [
                        'top',
                        'top-start',
                        'top-end',
                        'bottom',
                        'bottom-start',
                        'bottom-end',
                        'left',
                        'left-start',
                        'left-end',
                        'right',
                        'right-start',
                        'right-end'
                    ].indexOf(value) !== -1
                )
            }
        },

        hideOnClick: {
            type: Boolean,
            default: true
        },

        hideOnClickOutside: {
            type: Boolean,
            default: true
        },

        offset: {
            type: Number,
            default: 8
        },

        hideDelay: {
            type: Number,
            default: 200
        }
    },

    data() {
        return {
            emitter: mitt(),
            visible: false,
            position: {
                left: 0,
                top: 0
            },
            triggerElm: null,
            dropdownElm: null,
            hideTimer: null
        }
    },

    computed: {
        style() {
            let style = { ...this.position }

            style.width = this.minWidth

            return style
        }
    },

    mounted() {
        this.initEvent()

        if (this.isOpen) this.show()

        this.emitter.on('item-click', this.handleItemClick)
    },

    beforeUnmount() {
        this.emitter.off('item-click')
    },

    methods: {
        handleClickOutside() {
            if (this.visible && this.hideOnClickOutside) {
                this.hide()
            }
        },

        handleEscape() {
            if (this.visible) {
                this.hide()
            }
        },

        handleClick() {
            this.visible ? this.hide() : this.show()
        },

        handleItemClick(item) {
            if (item.hideOnClick && this.hideOnClick) {
                this.hide()
            }
        },

        getPlacement(placement) {
            return placement.split('-')[0]
        },

        getVariation(placement) {
            return placement.split('-')[1]
        },

        getMainAxis(placement) {
            return ['top', 'bottom'].indexOf(placement) >= 0 ? 'left' : 'top'
        },

        getPosition(trigger, container, placement) {
            const basePlacement = this.getPlacement(placement)
            const variation = this.getVariation(placement)
            const centerX = 0.5 * (trigger.offsetWidth - container.offsetWidth)
            const centerY = 0.5 * (trigger.offsetHeight - container.offsetHeight)

            let position

            switch (basePlacement) {
                case 'top':
                    position = {
                        left: centerX,
                        top: -container.offsetHeight - this.offset
                    }
                    break
                case 'bottom':
                    position = {
                        left: centerX,
                        top: this.offset + trigger.offsetHeight
                    }
                    break
                case 'left':
                    position = {
                        left: -container.offsetWidth - this.offset,
                        top: centerY
                    }
                    break
                case 'right':
                    position = {
                        left: this.offset + trigger.offsetWidth,
                        top: centerY
                    }
                    break
            }

            const mainAxis = this.getMainAxis(basePlacement)
            const offset = mainAxis === 'top' ? 'offsetHeight' : 'offsetWidth'

            switch (variation) {
                case 'start':
                    position[mainAxis] =
                        Math.floor(position[mainAxis]) -
                        Math.floor(0.5 * (trigger[offset] - container[offset]))
                    break
                case 'end':
                    position[mainAxis] =
                        Math.floor(position[mainAxis]) +
                        Math.ceil(0.5 * (trigger[offset] - container[offset]))
                    break
            }

            return position
        },

        show() {
            if (this.hideTimer) {
                clearTimeout(this.hideTimer)
                this.hideTimer = null
            }

            this.visible = true

            document.fonts.ready.then(() => {
                this.$nextTick(() => {
                    const position = this.getPosition(
                        this.triggerElm,
                        this.dropdownElm,
                        this.placement
                    )

                    this.position = {
                        left: `${position.left}px`,
                        top: `${position.top}px`
                    }
                })
            })
        },

        hide() {
            clearTimeout(this.hideTimer)

            this.hideTimer = setTimeout(
                () => {
                    this.visible = false
                },
                this.trigger === 'click' ? 0 : this.hideDelay
            )
        },

        initEvent() {
            this.triggerElm = this.$slots.default()[0].elm
            this.dropdownElm = this.$refs.dropdown

            if (this.trigger === 'click') {
                this.triggerElm.addEventListener('click', this.handleClick)
            } else if (this.trigger === 'hover') {
                this.triggerElm.addEventListener('mouseenter', this.show)
                this.triggerElm.addEventListener('mouseleave', this.hide)
                this.dropdownElm.addEventListener('mouseenter', this.show)
                this.dropdownElm.addEventListener('mouseleave', this.hide)
            }
        }
    }
}
</script>

<style lang="scss">
.dropdown-component {
    position: relative;

    display: inline-flex;
    align-self: flex-start;
    align-items: center;

    user-select: none;
}

.dropdown-component__menu {
    display: flex;
    flex-direction: column;

    position: absolute;

    top: 34px;
    right: 0;

    z-index: z-index('above');

    min-width: 224px;

    padding: 4px 0;

    background: #fff;
    border: #f1f1f1 solid 1px;
    border-radius: 6px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

    will-change: opacity, transform;

    &.-top {
        transform-origin: bottom center;
    }

    &.-top-start {
        transform-origin: bottom left;
    }

    &.-top-end {
        transform-origin: bottom right;
    }

    &.-bottom {
        transform-origin: top center;
    }

    &.-bottom-start {
        transform-origin: top left;
    }

    &.-bottom-end {
        transform-origin: top right;
    }

    &.-left {
        transform-origin: center right;
    }

    &.-left-start {
        transform-origin: top right;
    }

    &.-left-end {
        transform-origin: bottom right;
    }

    &.-right {
        transform-origin: center left;
    }

    &.-right-start {
        transform-origin: top left;
    }

    &.-right-end {
        transform-origin: bottom left;
    }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: opacity 0.25s cubic-bezier(0, 1, 0.4, 1),
        transform 0.25s cubic-bezier(0.18, 1.25, 0.4, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
    transform: scale(0.9);

    opacity: 0;
}

.slide-fade-enter-to {
    transform: scale(1);

    opacity: 1;
}
</style>
