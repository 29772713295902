<template>
    <a
        :class="[
            'link-component',
            `link-component--${type}`,
            {
                'is-disabled': disabled,
                'has-underline': underline && !disabled
            }
        ]"
        :href="disabled ? null : href"
        v-bind="$attrs"
        @click="handleClick"
    >
        <slot />
    </a>
</template>

<script>
export default {
    name: 'LinkComponent',

    props: {
        type: {
            type: String,
            default: 'default',
            validator(value) {
                return [
                    'default',
                    'primary',
                    'success',
                    'info',
                    'warning',
                    'danger',
                    'light'
                ].includes(value)
            }
        },

        href: {
            type: String,
            default: ''
        },

        route: {
            type: [String, Object],
            default: ''
        },

        underline: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        handleClick(event) {
            if (!this.disabled && !this.href) {
                event.preventDefault()

                if (this.route) {
                    this.$router.push(this.route).catch((err) => {
                        return err
                    })
                }

                this.$emit('click', this)
            }
        }
    }
}
</script>

<style lang="scss">
.link-component {
    display: flex;

    align-items: center;
    justify-content: center;

    color: #34495e;

    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    text-decoration: none !important;

    transition: color 0.24s cubic-bezier(0.455, 0.03, 0.515, 0.955);

    &:not(.is-disabled):hover {
        color: lighten(#34495e, 10);

        &.has-underline {
            text-decoration: underline !important;
        }
    }

    &.is-disabled {
        color: rgba(#34495e, 0.6);

        cursor: not-allowed;
    }
}

.link-component--primary {
    color: #59a8ff;

    &:not(.is-disabled):hover {
        color: lighten(#59a8ff, 10);
    }

    &.is-disabled {
        color: rgba(#59a8ff, 0.6);
    }
}

.link-component--success {
    color: #2ecc71;

    &:not(.is-disabled):hover {
        color: lighten(#2ecc71, 10);
    }

    &.is-disabled {
        color: rgba(#2ecc71, 0.6);
    }
}

.link-component--warning {
    color: #ffba59;

    &:not(.is-disabled):hover {
        color: lighten(#ffba59, 10);
    }

    &.is-disabled {
        color: rgba(#ffba59, 0.6);
    }
}

.link-component--danger {
    color: #e74c3c;

    &:not(.is-disabled):hover {
        color: lighten(#e74c3c, 10);
    }

    &.is-disabled {
        color: rgba(#e74c3c, 0.6);
    }
}

.link-component--info {
    color: #95a5a6;

    &:not(.is-disabled):hover {
        color: lighten(#95a5a6, 10);
    }

    &.is-disabled {
        color: rgba(#95a5a6, 0.6);
    }
}

.link-component--light {
    color: #fff;

    &:not(.is-disabled):hover {
        color: rgba(#fff, 0.6);
    }

    &.is-disabled {
        color: rgba(#fff, 0.25);
    }
}
</style>
