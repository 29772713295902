<template>
    <div class="integration-component">
        <RowComponent justify="space-between" align="center">
            <div class="integration-component__content">
                <div class="integration-component__icon">
                    <slot name="icon" />
                </div>
                <div class="integration-component__heading">
                    <h1>{{ integration.name }}</h1>
                    <p>{{ integration.description }}</p>
                </div>
            </div>
            <div class="integration-component__actions">
                <template v-if="!integrationData">
                    <ButtonComponent
                        class="button-component--icon"
                        type="success"
                        shadow
                        @click="handleConnect"
                    >
                        <i class="button-component__icon button-component__icon--plus-circle" />
                        <span>Connect</span>
                    </ButtonComponent>
                </template>
                <template v-else>
                    <template
                        v-if="
                            !integrationAccount.active &&
                            integrationAccount.inactive_reason === 'invalid-grant'
                        "
                    >
                        <RowComponent align="center">
                            <PopoverComponent
                                v-model:visible="visibleAccountPopover"
                                width="300px"
                                placement="bottom-end"
                                :close-delay="0"
                            >
                                <template #trigger>
                                    <ToggleComponent
                                        v-model="integrationAccount.active"
                                        short
                                        locked
                                        @mouseenter="visibleAccountPopover = true"
                                        @mouseleave="visibleAccountPopover = false"
                                    />
                                </template>
                                <p>
                                    You <strong>cannot</strong> enable this because the provided
                                    authorization grant or refresh token is invalid, expired or
                                    revoked.
                                </p>
                            </PopoverComponent>
                            <DropdownComponent placement="bottom-end">
                                <ButtonComponent type="info">
                                    <i
                                        class="button-component__icon button-component__icon--more-vertical"
                                    />
                                </ButtonComponent>
                                <template #dropdown>
                                    <DropdownItemComponent @click="handleReconnect">
                                        <i
                                            class="dropdown-item-component__icon dropdown-item-component__icon--unlock"
                                        />
                                        <span>Authorize</span></DropdownItemComponent
                                    >
                                    <DropdownItemComponent
                                        class="dropdown-item-component--danger"
                                        divided
                                        @click="handleDelete"
                                    >
                                        <i
                                            class="dropdown-item-component__icon dropdown-item-component__icon--trash"
                                        /><span>Delete</span></DropdownItemComponent
                                    >
                                </template>
                            </DropdownComponent>
                        </RowComponent>
                    </template>
                    <template v-else>
                        <RowComponent align="center">
                            <ToggleComponent
                                v-model="integrationActive"
                                short
                                prevent
                                @change="handleChange"
                            />
                            <DropdownComponent placement="bottom-end">
                                <ButtonComponent type="info">
                                    <i
                                        class="button-component__icon button-component__icon--more-vertical"
                                    />
                                </ButtonComponent>
                                <template #dropdown>
                                    <DropdownItemComponent @click="handleConfigure">
                                        <i
                                            class="dropdown-item-component__icon dropdown-item-component__icon--settings"
                                        />
                                        <span>Configure</span></DropdownItemComponent
                                    >
                                    <slot name="options" />
                                    <DropdownItemComponent
                                        class="dropdown-item-component--danger"
                                        divided
                                        @click="handleDelete"
                                    >
                                        <i
                                            class="dropdown-item-component__icon dropdown-item-component__icon--trash"
                                        /><span>Delete</span></DropdownItemComponent
                                    >
                                </template>
                            </DropdownComponent>
                        </RowComponent>
                    </template>
                </template>
            </div>
        </RowComponent>
        <Teleport to="body">
            <ModalComponent
                ref="modal"
                class="integration-component__modal"
                width="800px"
                fullscreen
                :close-on-press-escape="false"
                :close-on-click-outside="false"
                @closed="handleClose"
            >
                <template #header>
                    <div class="integration-component__modal__header">
                        <div class="integration-component__modal__icon">
                            <slot name="icon" />
                        </div>
                        <div class="integration-component__modal__heading">
                            <h1>{{ integration.name }}</h1>
                            <p>{{ integration.description }}</p>
                        </div>
                    </div>
                </template>
                <transition-group name="slide-in-out" tag="div">
                    <div
                        v-if="activeStep === 0"
                        class="integration-component__modal__content integration-component__modal__content--login"
                    >
                        <template v-if="!hasAccount">
                            <h2>Connect SurveyLegend to your {{ integration.name }} account</h2>
                            <p>
                                There's no {{ integration.name }} account linked with SurveyLegend
                                yet.
                            </p>
                            <ButtonComponent
                                class="button-component--icon"
                                type="success"
                                shadow
                                @click="handleLogin"
                            >
                                <i class="button-component__icon button-component__icon--log-in" />
                                <span>Login to {{ integration.name }}</span>
                            </ButtonComponent>
                        </template>
                        <template v-else>
                            <h2>You're already connected to {{ integration.name }}</h2>
                            <p>Choose an account from the selection below, or add a new one.</p>
                            <SelectComponent
                                :options="integrationAccounts"
                                placeholder="Choose an account..."
                                footer-button="Add account..."
                                @select="handleSelectAccount"
                            />
                        </template>
                    </div>
                    <div
                        v-if="activeStep === 1"
                        class="integration-component__modal__content integration-component__modal__content--configure"
                    >
                        <slot name="configure" />
                    </div>
                    <div
                        v-if="activeStep === 2"
                        key="done"
                        class="integration-component__modal__content integration-component__modal__content--done"
                    >
                        <h2>Almost done...</h2>
                        <p>Proceed to save your settings by activating your integration.</p>
                        <ButtonComponent
                            class="button-component--icon"
                            type="success"
                            shadow
                            @click="handleActivate"
                        >
                            <i
                                class="button-component__icon button-component__icon--toggle-right"
                            />
                            <span>Activate</span>
                        </ButtonComponent>
                    </div>
                </transition-group>
                <template #footer>
                    <StepsComponent :active="activeStep">
                        <StepComponent title="Login" />
                        <StepComponent title="Configure" />
                        <StepComponent title="Done" />
                    </StepsComponent>
                </template>
            </ModalComponent>
            <UpgradeComponent
                ref="upgrade"
                :name="`integrations-${slug}`"
                :image="`modal-upgrade-${slug}.png`"
            />
            <WarningComponent
                ref="warning-delete"
                name="delete-webhook"
                image="move-survey-to-trash.png"
                word="delete"
            />
        </Teleport>
    </div>
</template>

<script>
import UpgradeComponent from '@/components/Upgrade'
import WarningComponent from '@/components/Warning'
import IntegrationService from '@/services/integration-service'
import { mapGetters } from 'vuex'

export default {
    name: 'IntegrationComponent',

    components: {
        UpgradeComponent,
        WarningComponent
    },

    props: {
        integration: {
            type: Object,
            default: () => ({
                name: '',
                description: ''
            })
        }
    },

    data() {
        return {
            visibleAccountPopover: false,
            activeStep: 0
        }
    },

    computed: {
        ...mapGetters({
            accounts: 'integrations/accounts',
            integrations: 'integrations/integrations',
            permissions: 'auth/permissions'
        }),

        slug() {
            return this.integration.name
                .toLowerCase()
                .trim()
                .replace(/[\s\W-]+/g, '-')
        },

        integrationAccounts() {
            const accounts = []

            this.accounts.forEach((account) => {
                if (account.type === this.slug) {
                    accounts.push({
                        label: account.email,
                        value: account.id
                    })
                }
            })

            return accounts
        },

        integrationData() {
            return this.integrations.find((integration) => {
                return (
                    integration.type === this.slug &&
                    integration.survey_id === this.$route.params.id
                )
            })
        },

        integrationAccount() {
            return this.accounts.find((account) => {
                return account.id === this.integrationData.integration_id
            })
        },

        integrationActive() {
            return this.integrationData.active ? true : false
        },

        hasAccount() {
            return this.integrationAccounts.length > 0
        }
    },

    created() {
        window.addEventListener('message', this.handleMessage, false)
    },

    beforeUnmount() {
        window.removeEventListener('message', this.handleMessage)
    },

    methods: {
        async handleConnect() {
            const permission = `integrations-${this.slug}`
            const hasPermission = this.permissions[permission]

            if (!hasPermission) {
                this.track('open-upgrade-modal', this.slug)

                const isConfirmed = await this.$refs.upgrade.open()

                this.$refs.upgrade.close()

                if (isConfirmed)
                    window.location.href = `https://www.surveylegend.com/upgrade?source=upgrade_modal&type=${this.slug}&level=${this.permissions.level}`

                return
            } else if (permission) {
                this.track('has-permission', permission)

                this.$refs.modal.open()

                const upgrade = document.getElementsByClassName('top-bar-upgrade-icon')[0]
                const chat = document.getElementById('chat-widget-container')

                if (upgrade) upgrade.style.display = 'none'
                if (chat) chat.style.display = 'none'
            }
        },

        async handleChange({ value }) {
            await this.$store.dispatch('integrations/updateIntegration', {
                survey_id: this.$route.params.id,
                integration_id: this.integrationData.integration_id,
                data: {
                    active: value
                }
            })
        },

        async handleLogin() {
            const { data } = await IntegrationService.fetchAuthorizeUrl({ slug: this.slug })

            this.openWindow(data)
        },

        handleActivate() {
            this.$emit('integration-activate')
        },

        handleMessage(event) {
            if (
                event.origin !== process.env.VUE_APP_API_URL ||
                !event.data ||
                event.data.source !== 'surveylegend-api'
            ) {
                return
            }

            const { data } = event

            this.$emit('integration-message', data)
        },

        handleSelectAccount(option) {
            if (option.footerButton) {
                this.handleLogin()

                return
            }

            this.$emit('integration-select-account', option.value)
        },

        handleReconnect() {
            this.handleConnect()
        },

        handleConfigure() {
            this.handleConnect()
        },

        async handleDelete() {
            const isConfirmed = await this.$refs['warning-delete'].open()

            this.$refs['warning-delete'].close()

            if (!isConfirmed) return

            await this.$store.dispatch('integrations/deleteIntegration', {
                survey_id: this.$route.params.id,
                integration_id: this.integrationData.integration_id
            })
        },

        openWindow(url, title = '', options = {}) {
            options = { width: 480, height: 541, ...options }

            options.top = window.innerHeight / 2 - options.height / 2 + window.screenTop
            options.left = window.innerWidth / 2 - options.width / 2 + window.screenLeft

            const optionsStr = Object.keys(options)
                .reduce((acc, key) => {
                    acc.push(`${key}=${options[key]}`)

                    return acc
                }, [])
                .join(',')

            const openedWindow = window.open(url, title, optionsStr)

            openedWindow.focus()

            return openedWindow
        },

        next() {
            this.activeStep++
        },

        handleClose() {
            const upgrade = document.getElementsByClassName('top-bar-upgrade-icon')[0]
            const chat = document.getElementById('chat-widget-container')

            if (upgrade) upgrade.style.display = 'block'
            if (chat) chat.style.display = 'block'

            this.$emit('integration-close')

            Object.assign(this.$data, this.$options.data.apply(this))
        },

        close() {
            this.$refs.modal.close()
        },

        track(action, name) {
            this.$gtag.event(action, {
                event_category: name,
                event_label: this.permissions.level
            })
        }
    }
}
</script>

<style lang="scss">
.integration-component {
    display: flex;

    align-items: center;

    height: 120px;

    padding: 20px 30px;

    border-top: 1px solid #f3f3f3;
}

.integration-component__icon {
    display: flex;

    align-items: center;

    width: 60px;

    margin-right: 30px;
}

.integration-component__content {
    display: flex;
}

.integration-component__heading {
    h1 {
        font-size: 15px;
        font-weight: 500;

        margin-bottom: 5px;
    }
}

.integration-component__actions {
    padding-left: 40px;

    .button-component__icon--plus-circle {
        @include icon('~@/assets/svg/icons/plus-circle.svg');
    }

    .button-component__icon--more-vertical {
        @include icon('~@/assets/svg/icons/more-vertical.svg');
    }

    .dropdown-component {
        margin-left: 20px;

        .button-component {
            padding: 9px;
        }

        .button-component__icon {
            width: 24px;
            height: 24px;

            margin: 0;
        }
    }

    .dropdown-item-component--danger,
    .dropdown-item-component--danger:hover {
        color: #f75851;
    }

    .dropdown-item-component {
        flex-direction: row;
        align-items: center;

        font-weight: 500;
    }

    .dropdown-item-component__icon--settings {
        @include mask-icon('~@/assets/svg/icons/settings.svg');

        background-color: #34495e;
    }

    .dropdown-item-component__icon--unlock {
        @include mask-icon('~@/assets/svg/icons/unlock.svg');

        background-color: #34495e;
    }

    .dropdown-item-component__icon--trash {
        @include mask-icon('~@/assets/svg/icons/trash.svg');

        background-color: #f75851;
    }

    .dropdown-item-component__icon--file-text {
        @include mask-icon('~@/assets/svg/icons/file-text.svg');

        background-color: #34495e;
    }
}

.integration-component__modal {
    .modal-component__container {
        overflow: hidden;
    }

    .modal-component__header {
        padding: 0 30px;

        border-bottom: 1px solid #eaeaea;
    }

    .slide-in-out-enter-active,
    .slide-in-out-leave-active {
        transition: all 0.5s ease-in-out;
    }

    .slide-in-out-enter {
        transform: translateX(100%);
        opacity: 0;
    }

    .slide-in-out-leave-to {
        opacity: 0;
        transform: translateX(-100%);
    }
}

.integration-component__modal__header {
    display: flex;

    width: 600px;
}

.integration-component__modal__icon {
    display: flex;

    align-items: center;

    width: 60px;

    margin-right: 30px;
}

.integration-component__modal__heading {
    color: #2c3e50;

    h1 {
        font-size: 15px;
        font-weight: 500;

        margin-bottom: 5px;
    }

    p {
        font-size: 14px;
    }
}

.integration-component__modal__content {
    position: absolute;

    display: flex;

    flex-direction: column;
    align-items: center;

    width: 100%;

    will-change: transform;

    h2 {
        color: #2c3e50;

        font-size: 15px;
        font-weight: 500;
        text-align: center;

        margin-bottom: 5px;
    }

    p {
        color: #7f8c8d;

        font-size: 14px;
        text-align: center;
    }

    .button-component__icon--log-in {
        @include icon('~@/assets/svg/icons/log-in.svg');
    }

    .button-component__icon--toggle-right {
        @include icon('~@/assets/svg/icons/toggle-right.svg');
    }

    .select-component__button {
        span {
            padding-top: 0;
            margin: 0;
        }

        &.is-open {
            border-radius: 6px;
        }

        &.is-disabled:hover {
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
        }
    }
}

.integration-component__modal__content--login,
.integration-component__modal__content--done {
    .button-component,
    .select-component {
        margin-top: 50px;
    }
}
</style>
