function beforeMount(element, binding) {
    const escapeHandler = (event) => {
        if (event.keyCode === 27) {
            binding.value(event, element)
        }
    }

    element.__escapeHandler__ = escapeHandler

    document.addEventListener('keyup', element.__escapeHandler__)
}

function unmounted(element) {
    document.removeEventListener('keyup', element.__escapeHandler__)
}

const directive = {
    beforeMount,
    unmounted
}

export default directive
