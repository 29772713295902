<template>
    <div v-if="theme && !loading" :class="['theme-component', theme.mood, theme.colors]">
        <div class="theme-component__background">
            <div class="theme-component__background__overlay" />
            <div
                :class="[
                    'theme-component__background__inner',
                    {
                        'has-blur': theme.blur,
                        'is-tile': image && theme.tile
                    }
                ]"
                :style="style"
            />
        </div>
    </div>
</template>

<script>
import { db } from '@/firebase'
import themes from '@/assets/json/themes'

const surveys = db.ref('v2/survey_meta/')

export default {
    name: 'ThemeComponent',

    data() {
        return {
            survey: {},
            loading: false,
            image: null,
            themes
        }
    },

    computed: {
        theme() {
            if (Object.keys(this.survey).length === 0) return

            const userId = this.survey.userId || this.survey.user_id
            const custom = this.survey.theme.themeId === 'custom-theme'

            let background

            if (custom) {
                const activeTheme = this.themes.find((theme) => {
                    return 'theme-' + theme.icon.split('.')[0] === 'theme-default-bg'
                })

                background = this.survey.customWallpaper
                    ? `/survey-media/${userId}/${this.survey.customWallpaper}`
                    : activeTheme.icon
            } else {
                const activeTheme = this.themes.find((theme) => {
                    return 'theme-' + theme.icon.split('.')[0] === this.survey.theme.themeId
                })

                background = activeTheme.icon
            }

            const mood = this.survey.themeMood
                ? `is-${this.survey.themeMood.slice(6)}`
                : `is-${this.survey.theme.mood.slice(6)}`

            return {
                blur: Object.prototype.hasOwnProperty.call(this.survey, 'blurBackground')
                    ? this.survey.blurBackground
                    : this.survey.theme.blurBackground,
                tile: !custom && this.survey.theme.tiledBackground,
                mood,
                colors: this.survey.themePalette || this.survey.theme.colorPalette,
                background,
                category: this.survey.theme.category
            }
        },

        style() {
            let style = {}

            if (this.image) style.backgroundImage = `url('${this.image.src}')`

            return style
        }
    },

    watch: {
        '$route.params.id': {
            immediate: true,
            handler(id) {
                if (id) this.$rtdbBind('survey', surveys.child(id))
            }
        },

        theme: {
            immediate: true,
            handler(theme) {
                if (theme) {
                    const themeImage = `${process.env.VUE_APP_CDN_URL}/img/themes/${theme.category}/${theme.background}`

                    if (!this.image || this.image.src !== themeImage) {
                        this.setImage(themeImage)
                    }
                }
            }
        }
    },

    methods: {
        setImage(src) {
            this.loadImage(src)
                .catch(() => new Image())
                .then((image) => {
                    this.image = image
                })
        },

        async loadImage(src) {
            return new Promise((resolve, reject) => {
                const image = new Image()

                this.loading = true
                this.$emit('loading')

                image.addEventListener('load', () => {
                    resolve(image)

                    this.loading = false
                    this.$emit('complete')
                })
                image.addEventListener('error', reject)

                image.src = src
            })
        }
    }
}
</script>

<style lang="scss">
.theme-component {
    position: absolute;

    top: 0;

    z-index: z-index('below');

    width: 100%;
    height: 100%;

    &.palette-lake {
        --color-primary: #04e6f7;
        --color-secondary: #04cddb;
    }

    &.palette-sun {
        --color-primary: #ffd670;
        --color-secondary: #fc0;
    }

    &.palette-sky {
        --color-primary: #59a8ff;
        --color-secondary: #007aff;
    }
}

.theme-component__background {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    pointer-events: none;
}

.theme-component__background__overlay {
    position: absolute;

    z-index: z-index('above');

    width: 100%;
    height: 100%;

    .is-light & {
        background-color: rgba(255, 255, 255, 0.55);
    }

    .is-dark & {
        background-color: rgba(0, 0, 0, 0.4);
    }
}

.theme-component__background__inner {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-position: center top;
    background-size: cover;

    &.has-blur {
        filter: blur(5px);
    }

    &.is-tile {
        background-repeat: repeat;
        background-size: 400px;
    }
}
</style>
