<template>
    <div class="copy-component">
        <ButtonComponent
            :class="[
                `is-${size}`,
                {
                    'is-translucent': translucent
                }
            ]"
            shadow
            @click="onClick"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="copy-component__icon--clipboard"
            >
                <path
                    d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"
                ></path>
                <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
            </svg>
            <span>{{ buttonText }}</span>
            <transition name="copied" @after-enter="afterEnter">
                <div v-if="copied" class="copy-component__copied">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="copy-component__icon--star"
                    >
                        <polygon
                            points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"
                        ></polygon>
                    </svg>
                    <span>Copied!</span>
                </div>
            </transition>
        </ButtonComponent>
    </div>
</template>

<script>
export default {
    name: 'CopyComponent',

    props: {
        value: {
            type: [String, Number],
            required: true
        },

        buttonText: {
            type: String,
            default: 'Copy'
        },

        size: {
            type: String,
            default: 'medium',
            validator(value) {
                return ['medium', 'small'].indexOf(value) !== -1
            }
        },

        translucent: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            copied: false
        }
    },

    methods: {
        onClick() {
            this.$copyText(this.value, undefined, (error) => {
                if (error) {
                    this.copied = false
                } else {
                    this.copied = true
                }
            })
        },

        afterEnter() {
            this.copied = false
        }
    }
}
</script>

<style scoped lang="scss">
.copy-component {
    .button-component {
        position: relative;

        color: #34495e;

        height: 38px;

        padding: 0 16px 0 14px;

        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1) !important;

        &:hover {
            background-color: #fff;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
        }

        &.is-small {
            height: 34px;

            padding: 0 14px 0 12px;
        }

        &.is-translucent {
            background-color: rgba(#fff, 0.85);

            &:hover {
                background-color: #fff;
            }
        }

        span {
            margin-top: 1px;
        }
    }
}

.copy-component__icon--clipboard {
    margin-right: 8px;
}

.copy-component__copied {
    position: absolute;

    top: -10px;
    left: 0;

    display: flex;

    justify-content: center;
    align-items: center;

    color: #1abc9c;

    width: 100%;
    height: 100%;

    opacity: 0;
}

.copy-component__icon--star {
    fill: #ffba59;
    stroke: #ffba59;

    flex-shrink: 0;

    margin: 0 4px 2px 0;

    animation: rotate 1.5s linear infinite;
}

.copied-enter-active {
    animation: slide-out 1s ease;
}

@keyframes rotate {
    from {
        transform: rotateY(0deg);
    }

    to {
        transform: rotateY(360deg);
    }
}

@keyframes slide-out {
    0% {
        opacity: 0;
        transform: translateY(0);
    }

    20%,
    70% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateY(-28px);
    }
}
</style>
