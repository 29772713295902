<template>
    <div class="expand-component">
        <CardComponent>
            <template #header>
                <slot name="header" />
            </template>
            <!-- <transition
                :css="false"
                @before-enter="beforeEnter"
                @enter="enter"
                @after-enter="afterEnter"
                @leave="leave"
            > -->
            <div v-if="isExpanded" class="expand-component__container">
                <div class="expand-component__inner">
                    <slot />
                </div>
            </div>
            <!-- </transition> -->
            <template v-if="expandable" #footer>
                <RowComponent
                    class="expand-component__footer"
                    justify="center"
                    align="center"
                    @click="handleExpand"
                >
                    <i :class="['expand-component__icon', { 'is-expanded': expanded }]" />
                </RowComponent>
            </template>
        </CardComponent>
    </div>
</template>

<script>
import Velocity from 'velocity-animate'

export default {
    name: 'ExpandComponent',

    props: {
        expandable: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            expanded: false
        }
    },

    computed: {
        isExpanded() {
            return this.expandable ? this.expanded : true
        }
    },

    methods: {
        beforeEnter(element) {
            element.style.opacity = 0
            element.style.height = 0
        },

        enter(element, done) {
            const height = element.scrollHeight

            Velocity(element, { opacity: 1, height }, { duration: 400, complete: done })
        },

        leave(element, done) {
            Velocity(element, { opacity: 0, height: 0 }, { duration: 400, complete: done })
        },

        afterEnter(element) {
            element.style.height = 'auto'
        },

        handleExpand() {
            this.expanded = !this.expanded
        }
    }
}
</script>

<style lang="scss">
.expand-component {
    .card-component__header {
        padding: 18px 20px;
    }

    .card-component__content,
    .card-component__footer {
        margin-top: -1px;
        padding: 0;
    }
}

.expand-component__container {
    overflow: hidden;

    will-change: opacity, height;
}

.expand-component__inner {
    padding: 20px 20px 21px;
}

.expand-component__footer {
    padding: 12px 20px;

    cursor: pointer;
}

.expand-component__icon {
    @include icon('~@/assets/svg/icons/chevron-down.svg');

    display: inline-block;

    width: 20px;
    height: 20px;

    transition: all 0.2s ease;

    :hover > & {
        transform: translateY(1px);
    }

    &.is-expanded {
        @include icon('~@/assets/svg/icons/chevron-up.svg');

        :hover > & {
            transform: translateY(-1px);
        }
    }
}
</style>
