<template>
    <div class="preview-component">
        <CardComponent>
            <template #header>
                <slot name="header" />
            </template>
            <template v-if="expand" #default>
                <div class="preview-component__content">
                    <div class="preview-component__options">
                        <span class="preview-component__title">{{ title }}</span>
                        <span v-if="lead" class="preview-component__lead">{{ lead }}</span>
                        <DividerComponent />
                        <slot name="options" />
                    </div>
                    <div class="preview-component__preview">
                        <slot name="preview-toolbar" />
                        <CardComponent :style="style">
                            <slot name="preview" />
                        </CardComponent>
                    </div>
                </div>
            </template>
        </CardComponent>
    </div>
</template>

<script>
export default {
    name: 'PreviewComponent',

    props: {
        title: {
            type: String,
            default: 'Options'
        },

        lead: {
            type: String,
            default: ''
        },

        expand: {
            type: Boolean,
            default: false
        },

        maxHeight: {
            type: String,
            default: 'none'
        }
    },

    computed: {
        style() {
            let style = {}

            style.maxHeight = this.maxHeight

            return style
        }
    }
}
</script>

<style lang="scss">
.preview-component {
    .card-component {
        margin-bottom: 0;
    }

    .card-component__header {
        padding: 15px 20px;
    }

    .card-component__content {
        padding: 0;
    }
}

.preview-component__content {
    display: flex;
}

.preview-component__options {
    position: relative;

    max-width: 320px;

    padding: 20px 20px 40px 20px;
}

.preview-component__title {
    display: block;

    font-weight: 500;

    margin-bottom: 5px;
}

.preview-component__preview {
    position: relative;

    flex: 1;

    padding: 20px;

    background-color: #fafafa;

    border-left: 1px solid #f3f3f3;
    border-bottom-right-radius: 6px;

    overflow: hidden;

    .card-component {
        margin: 0;

        overflow-x: hidden;
        overflow-y: auto;
    }
}
</style>
