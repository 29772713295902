<template>
    <div
        :ref="id"
        :id="id"
        :class="[
            'dropzone-component',
            this.survey.themeMood
                ? `is-${this.survey.themeMood.slice(6)}`
                : `is-${this.survey.theme?.mood.slice(6)}`,
            { 'is-empty': isEmpty }
        ]"
        @dragenter="handleDragenter"
        @dragleave="handleDragleave"
        @dragover="handleDragover"
        @drop="handleDrop"
    >
        <div :class="['dropzone-component__placeholder', , { 'is-empty': isEmpty }]">
            <svg
                width="280"
                height="280"
                viewBox="0 0 100 100"
                fill="none"
                stroke="currentColor"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
            >
                <path
                    d="M23.6296 34.3993C23.1108 32.8162 24.0404 30.9736 25.6251 30.4531C27.2099 29.9343 29.0508 30.8639 29.5713 32.447L31.6084 38.6464C32.8532 37.3119 33.5471 35.5558 33.5507 33.7308C33.5507 31.8012 32.7842 29.9507 31.4198 28.5863C30.0554 27.2219 28.2049 26.4554 26.2753 26.4554C24.3458 26.4554 22.4953 27.2219 21.1309 28.5863C19.7665 29.9507 19 31.8012 19 33.7308C19 37.5805 21.9999 40.7001 25.7848 40.9562L23.6296 34.3993Z"
                    fill="#94DBFC"
                    stroke="#94DBFC"
                />
                <path
                    d="M72.2454 23.2027C62.5039 8.62376 38.6741 7.44141 29.1654 24.6029"
                    stroke-dasharray="3 3"
                />
                <path
                    d="M47.8551 88.0872L46.1622 82.9404C44.3812 83.5257 42.5354 83.253 41.1551 82.3899C40.628 82.1239 22.1162 67.1541 22.1162 67.1541C20.869 66.0267 20.8773 64.0494 22.0048 62.8039C22.6683 61.4885 25.304 61.5002 26.2885 62.4913L34.821 68.4545L23.6295 34.3993C23.1106 32.8162 24.0402 30.9737 25.625 30.4532C27.2098 29.9343 29.0506 30.8639 29.5711 32.447L36.4008 53.2387C37.0543 55.2143 39.0316 54.569 38.3814 52.5835L36.755 47.6346C36.168 45.8536 37.1641 44.2073 38.7472 43.6885C40.3303 43.168 42.108 43.9013 42.6933 45.6823L45.2958 53.6029C45.9477 55.5818 47.9283 54.9316 47.2747 52.951L45.6467 48.0005C45.0597 46.2195 46.0591 44.5731 47.6406 44.0543C49.2253 43.5338 51.0014 44.2655 51.5851 46.0482L53.6704 52.3873C54.3239 54.3678 56.3011 53.716 55.6493 51.7337L55.0606 49.9428C54.4752 48.1618 55.473 46.5155 57.0544 45.9966C58.6376 45.4778 60.4152 46.2078 61.0006 47.9905L66.3902 69.7034C67.042 71.6806 66.5049 74.052 65.2461 75.5619C64.7838 76.1506 64.1186 76.8091 63.3304 77.0669C62.8032 77.461 64.8919 82.4781 64.8919 82.4781"
                    stroke-width="1.5"
                />
                <path
                    d="M73.9448 41.0044C77.9628 41.0044 81.2201 37.7471 81.2201 33.7291C81.2201 29.711 77.9628 26.4537 73.9448 26.4537C69.9267 26.4537 66.6694 29.711 66.6694 33.7291C66.6694 37.7471 69.9267 41.0044 73.9448 41.0044Z"
                    fill="white"
                    stroke="#94DBFC"
                    stroke-width="2"
                />
            </svg>
            <div class="dropzone-component__placeholder__title">
                Press on fields or simply drag & drop them here to start...
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '@/firebase'

const surveys = db.ref('v2/survey_meta/')

export default {
    name: 'DropzoneComponent',

    props: {
        id: {
            type: String,
            default: 'dropzone'
        }
    },

    data() {
        return {
            survey: {},
            isEmpty: true,
            dragElement: null
        }
    },

    watch: {
        '$route.params.id': {
            immediate: true,
            handler(id) {
                this.$rtdbBind('survey', surveys.child(id))
            }
        }
    },

    mounted() {
        this.emitter.on('dragstart', this.handleDragstart)
    },

    methods: {
        checkEmpty() {
            this.isEmpty = !this.$refs[this.id].hasChildNodes()
        },

        handleDragstart(data) {
            const wrapper = document.createElement('div')

            wrapper.innerHTML = data
            wrapper.firstChild.style.pointerEvents = 'none'

            this.dragElement = wrapper.firstChild
        },

        handleDragenter(event) {
            let target = event.target

            if (target.id && target.id === this.id) {
                target.appendChild(this.dragElement)

                this.checkEmpty()
            }
        },

        handleDragleave(event) {
            let target = event.target

            if (target.id && target.id === this.id) {
                target.removeChild(this.dragElement)

                this.checkEmpty()
            }
        },

        handleDragover(event) {
            event.preventDefault()
            event.dataTransfer.dropEffect = 'copy'
        },

        handleDrop() {
            // event.target.removeChild(this.dragElement)
            // const data = event.dataTransfer.getData('text/html')
        }
    }
}
</script>

<style lang="scss">
.dropzone-component {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    &.is-light,
    &.is-classic {
        color: #34495e;
    }

    &.is-dark {
        color: white;
    }

    li {
        list-style-type: none;
    }
}

.dropzone-component__placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dropzone-component__placeholder__title {
    width: 400px;

    font-size: 26px;
    text-align: center;

    margin-top: 40px;
}
</style>
