<template>
    <div
        :class="[
            'toggle-component',
            {
                'is-short': short,
                'is-locked': locked,
                'is-disabled': disabled
            }
        ]"
        @click.prevent="onClick($event)"
    >
        <input
            ref="input"
            class="toggle-component__input"
            type="checkbox"
            :name="name"
            :true-value="activeValue"
            :false-value="inactiveValue"
            :disabled="disabled"
            @change="handleChange($event)"
        />
        <span
            v-if="inactiveText"
            :class="[
                'toggle-component__label',
                'toggle-component__label--left',
                { 'is-active': !checked }
            ]"
        >
            {{ inactiveText }}
        </span>
        <span
            :class="[
                'toggle-component__switch',
                {
                    'is-checked': checked
                }
            ]"
            :style="style"
        />
        <span
            v-if="activeText"
            :class="[
                'toggle-component__label',
                'toggle-component__label--right',
                { 'is-active': checked }
            ]"
        >
            {{ activeText }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'ToggleComponent',

    emits: ['change', 'update:modelValue'],

    props: {
        modelValue: {
            type: [Boolean, String, Number],
            default: false
        },

        activeValue: {
            type: [Boolean, String, Number],
            default: true
        },

        inactiveValue: {
            type: [Boolean, String, Number],
            default: false
        },

        activeText: {
            type: String,
            default: ''
        },

        inactiveText: {
            type: String,
            default: ''
        },

        activeColor: {
            type: String,
            default: '#2ecc71'
        },

        inactiveColor: {
            type: String,
            default: '#cccccc'
        },

        name: {
            type: String,
            default: ''
        },

        short: {
            type: Boolean,
            default: false
        },

        locked: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        },

        prevent: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        style() {
            const style = {}

            style.backgroundColor = this.checked ? this.activeColor : this.inactiveColor

            return style
        },

        checked() {
            return this.modelValue === this.activeValue
        }
    },

    watch: {
        checked() {
            this.$refs.input.checked = this.checked
        }
    },

    mounted() {
        this.$refs.input.checked = this.checked
    },

    methods: {
        handleChange(event) {
            const value = this.checked ? this.inactiveValue : this.activeValue

            this.$emit('change', {
                value,
                event
            })

            if (!this.prevent) {
                this.$emit('update:modelValue', value)
                this.$nextTick(() => {
                    this.$refs.input.checked = this.checked
                })
            }
        },

        onClick(event) {
            !this.locked && !this.disabled && this.handleChange(event)
        }
    }
}
</script>

<style lang="scss">
.toggle-component {
    position: relative;

    display: inline-flex;

    align-items: center;

    cursor: pointer;

    &.is-disabled {
        opacity: 0.3;
    }

    &.is-locked,
    &.is-disabled {
        cursor: not-allowed;
    }
}

.toggle-component__input {
    position: absolute;

    width: 0;
    height: 0;

    margin: 0;

    opacity: 0;
}

.toggle-component__switch {
    position: relative;

    width: 52px;
    height: 32px;

    border-radius: 16px;

    transition: background-color 0.2s ease 0.1s;

    &.is-checked {
        &:after {
            transform: translateX(20px);
        }
    }

    .is-short & {
        width: 44px;
        height: 24px;

        &:after {
            margin: -2px;

            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16), 0 0 1px 0 rgba(0, 0, 0, 0.1);
        }
    }

    .is-locked & {
        &:after {
            @include icon('~@/assets/svg/icons/lock.svg');

            background-size: 14px 14px;
        }
    }

    &:after {
        content: '';

        position: absolute;

        width: 28px;
        height: 28px;

        margin: 2px;

        background-color: #ffffff;
        border-radius: 100%;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.16), 0 0 1px 0 rgba(0, 0, 0, 0.1);

        transition: transform 0.2s cubic-bezier(1, 0, 0, 1);
    }
}

.toggle-component__label {
    color: #34495e;

    font-size: 14px;
    font-weight: 500;
    line-height: 1;

    transition: color 0.2s ease 0.1s;

    &.is-active {
        color: #59a8ff;
    }
}

.toggle-component__label--left {
    margin-right: 10px;
}

.toggle-component__label--right {
    margin-left: 10px;
}
</style>
