// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/svg/icons/edit.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/svg/icons/save.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".edit-component[data-v-458c4490]{width:100%}.edit-component .button-component[data-v-458c4490]{width:90px;padding:12px 16px 12px 14px}.edit-component__button[data-v-458c4490]{color:#34495e;background-color:#fff;border-radius:4px;-webkit-box-shadow:0 1px 3px 0 rgba(0,0,0,.1)!important;box-shadow:0 1px 3px 0 rgba(0,0,0,.1)!important}.edit-component__button[data-v-458c4490]:not(.is-disabled):hover{background-color:#fff;-webkit-box-shadow:0 4px 12px rgba(0,0,0,.1)!important;box-shadow:0 4px 12px rgba(0,0,0,.1)!important}.edit-component__icon[data-v-458c4490]{display:inline-block;width:16px;height:16px;margin:-2px 8px 0 0;background-size:contain}.edit-component__icon--edit[data-v-458c4490]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat}.edit-component__icon--save[data-v-458c4490]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-position:50%;background-repeat:no-repeat}", ""]);
// Exports
module.exports = exports;
