import { api } from './api'

export default {
    addAccount(payload) {
        return api.post('/users/me/integrations', payload)
    },

    listAccounts() {
        return api.get('/users/me/integrations')
    },

    addIntegration(payload) {
        return api.post(`/users/integrations/surveys/${payload.survey_id}`, payload.data)
    },

    getIntegration(payload) {
        return api.get(`/users/integrations/surveys/${payload.survey_id}`)
    },

    updateIntegration(payload) {
        return api.put(
            `/users/integrations/surveys/${payload.survey_id}/${payload.integration_id}`,
            payload.data
        )
    },

    deleteIntegration(payload) {
        return api.delete(
            `/users/integrations/surveys/${payload.survey_id}/${payload.integration_id}`
        )
    },

    listIntegrations(payload) {
        return api.get(`/users/integrations/surveys/${payload.id}`)
    },

    fetchAuthorizeUrl(payload) {
        return api.get(`/users/integrations/${payload.slug}/authorize`)
    },

    fetchMailchimpLists(payload) {
        return api.get(`/users/integrations/${payload.id}/mailchimp/lists`)
    },

    addGoogleSheetSheet(payload) {
        return api.post(`/users/integrations/${payload.id}/google-sheets/sheets`, payload.data)
    }
}
