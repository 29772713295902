<template>
    <transition name="fade">
        <div class="loading-component" :style="{ backgroundColor: overlay || '' }">
            <svg
                class="loading-component__box"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
            >
                <ellipse
                    class="loading-component__ellipse"
                    :style="style"
                    cx="12"
                    cy="12"
                    rx="10"
                    ry="10"
                />
            </svg>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'LoadingComponent',

    props: {
        strokeColor: {
            type: String,
            default: '#34495e'
        },

        strokeWidth: {
            type: String,
            default: '1.5'
        },

        overlay: {
            type: String,
            default: ''
        }
    },

    computed: {
        style() {
            const style = {}

            style.stroke = this.strokeColor
            style.strokeWidth = this.strokeWidth

            return style
        }
    }
}
</script>

<style lang="scss">
.loading-component {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: absolute;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    z-index: z-index('toolbar');

    justify-content: center;
}

.loading-component__box {
    width: 30px;
    height: 30px;
}

.loading-component__ellipse {
    animation: ellipse-tail 2.55s ease-in-out infinite, ellipse-rotation 0.7s linear infinite;
    transform-origin: 50% 50%;

    fill: transparent;
    stroke-dasharray: 60;
    stroke-dashoffset: 20;
    stroke-linecap: round;

    @keyframes ellipse-tail {
        0%,
        to {
            stroke-dashoffset: 5;
        }

        35%,
        45% {
            stroke-dashoffset: 57.2;
        }
    }

    @keyframes ellipse-rotation {
        0% {
            transform: scaleX(-1) rotate(0);
        }

        50% {
            transform: scaleX(-1) rotate(-180deg);
        }

        to {
            transform: scaleX(-1) rotate(-1turn);
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-to {
    opacity: 1;
}
</style>
