import { api } from './api'

export default {
    addNotificationEmail(payload) {
        return api.post(`/surveys/${payload.survey_id}/notification-emails`, payload.data)
    },

    listNotificationEmails(payload) {
        return api.get(`/surveys/${payload.survey_id}/notification-emails`)
    },

    updateNotificationEmail(payload) {
        return api.put(
            `/surveys/${payload.survey_id}/notification-emails/${payload.id}`,
            payload.data
        )
    },

    deleteNotificationEmail(payload) {
        return api.delete(`/surveys/${payload.survey_id}/notification-emails/${payload.id}`)
    }
}
