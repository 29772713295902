import { createApp } from 'vue'
import { createMetaManager, plugin as metaPlugin } from 'vue-meta'
import { rtdbPlugin } from 'vuefire'
import App from '@/App'
import store from '@/store'
import router from '@/router'
import mitt from 'mitt'
import VueGtag from 'vue-gtag'
import VueClipboard from 'vue3-clipboard'
import * as Components from '@/components'

const metaManager = createMetaManager()
const emitter = mitt()
const app = createApp(App)

app.config.globalProperties.emitter = emitter

app.use(store).use(router).use(metaManager).use(metaPlugin).use(rtdbPlugin)

app.use(
    VueGtag,
    {
        appName: 'SurveyLegend Tool',
        config: {
            id: process.env.VUE_APP_GOOGLE_UNIVERSAL_KEY,
            params: {
                anonymize_ip: true,
                send_page_view: false
            }
        },
        pageTrackerScreenviewEnabled: false
    },
    router
)

app.use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true
})

for (const componentName in Components) {
    const component = Components[componentName]

    app.component(component.name, component)
}

router.isReady().then(app.mount('#app'))
