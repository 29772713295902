<template>
    <div
        :class="[
            type === 'textarea' ? 'textarea-component' : 'input-component',
            { 'has-prepend': $slots.prepend, 'has-append': $slots.append }
        ]"
    >
        <label v-if="label" class="input-component__label">
            {{ label }}
        </label>
        <template v-if="type !== 'textarea'">
            <div :class="['input-component__container', { 'is-disabled': disabled }]">
                <div v-if="$slots.prepend" class="input-component__prepend">
                    <slot name="prepend" />
                </div>
                <input
                    ref="input"
                    v-focus="autofocus"
                    v-bind="$attrs"
                    class="input-component__input"
                    :placeholder="placeholder"
                    :autocomplete="autocomplete"
                    :spellcheck="spellcheck"
                    :disabled="disabled"
                    :readonly="readonly"
                    :type="type"
                    :value="modelValue"
                    :style="style"
                    @input="onInput"
                />
                <div v-if="$slots.append" class="input-component__append">
                    <slot name="append" />
                </div>
            </div>
        </template>
        <textarea
            v-else
            ref="textarea"
            v-bind="$attrs"
            :class="['textarea-component__textarea', { 'has-autosize': autosize }]"
            :row="row"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            :disabled="disabled"
            :readonly="readonly"
            :value="modelValue"
            @input="onTextareaInput"
            @change="resizeTextarea"
            @cut="resizeTextarea"
            @paste="resizeTextarea"
            @drop="resizeTextarea"
            @keydown="resizeTextarea"
        />
    </div>
</template>

<script>
import focus from '@/directives/focus'

export default {
    name: 'InputComponent',

    directives: {
        focus
    },

    inheritAttrs: false,

    emits: ['update:modelValue'],

    props: {
        modelValue: {
            type: [String, Number],
            default: ''
        },

        type: {
            type: String,
            default: 'text'
        },

        placeholder: {
            type: String,
            default: ''
        },

        align: {
            type: String,
            default: 'left',
            validator(value) {
                return ['left', 'center', 'right'].includes(value)
            }
        },

        autosize: {
            type: Boolean,
            default: false
        },

        autocomplete: {
            type: [String, Boolean],
            default: false
        },

        autofocus: {
            type: Boolean,
            default: false
        },

        spellcheck: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        },

        readonly: {
            type: Boolean,
            default: false
        },

        label: {
            type: String,
            default: ''
        },

        row: {
            type: Number,
            default: 2
        }
    },

    computed: {
        style() {
            const style = {}

            style.textAlign = this.align

            return style
        }
    },

    mounted() {
        this.resizeTextarea()
    },

    methods: {
        onInput(event) {
            this.$refs.input.value = event.target.value
            this.$emit('update:modelValue', event.target.value)
        },

        onTextareaInput(event) {
            this.$refs.textarea.value = event.target.value
            this.$emit('update:modelValue', event.target.value)
        },

        resizeTextarea() {
            if (!this.autosize) return

            setTimeout(() => {
                this.$refs.textarea.style.height = 'auto'
                this.$refs.textarea.style.height = `${this.$refs.textarea.scrollHeight}px`
            }, 0)
        }
    }
}
</script>

<style lang="scss">
.input-component {
    display: flex;
    flex-direction: column;

    width: 100%;
}

.input-component__label {
    color: #34495e;

    font-size: 15px;
    font-weight: 500;

    margin-bottom: 5px;
}

.input-component__container {
    display: flex;
    flex-direction: row;

    background-color: #f3f3f3;

    border-radius: 3px;

    transition: background-color 0.2s ease;

    &:not(.is-disabled):hover {
        background-color: #eaeaea;
    }

    &.is-disabled {
        opacity: 0.4;
    }
}

.input-component__input {
    flex-grow: 2;

    min-height: 40px;

    color: #2c3e50;

    font-size: 14px;

    padding: 0 12px;
    margin: 0;

    background-color: transparent;
    border: 0;
    outline: 0;

    &::placeholder {
        color: #65717e;

        font-style: italic;

        transition: color 0.3s ease;
    }

    &:focus {
        &::placeholder {
            color: #ffffff;
        }
    }

    .is-disabled & {
        cursor: not-allowed;
    }
}

.input-component__prepend,
.input-component__append {
    margin: 2px;
}

.textarea-component {
    display: flex;
    flex-direction: column;

    width: 100%;
}

.textarea-component__textarea {
    box-sizing: border-box;

    color: #2c3e50;

    font-size: 14px;
    line-height: 1.8;

    resize: vertical;

    min-height: 40px;

    padding: 8px 12px;
    margin: 0;

    background-color: #f3f3f3;
    border-radius: 3px;
    border: 0;
    outline: 0;
    overflow: hidden;

    transition: background-color 0.2s ease;

    &::placeholder {
        color: #65717e;

        font-style: italic;

        transition: color 0.3s ease;
    }

    &:focus {
        &::placeholder {
            color: #ffffff;
        }
    }

    &:not(.is-disabled):hover {
        background-color: #eaeaea;
    }

    &.has-autosize {
        resize: none;
    }

    &.is-disabled {
        opacity: 0.4;

        cursor: not-allowed;
    }
}
</style>
