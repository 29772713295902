<template>
    <li class="menu-group-component">
        <div class="menu-group-component__title">
            <template v-if="!$slots.title">{{ title }}</template>
            <slot v-else name="title" />
        </div>
        <ul>
            <slot />
        </ul>
    </li>
</template>

<script>
export default {
    name: 'MenuGroupComponent',

    inject: ['menu'],

    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss">
.menu-group-component {
    list-style-type: none;

    padding: 0 20px;
    margin-bottom: 20px;
}

.menu-group-component__title {
    color: #34495e;

    font-size: 15px;
    font-weight: 500;

    padding: 10px 0;
}
</style>
