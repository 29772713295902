// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/svg/icons/lock.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".toggle-component{position:relative;display:-webkit-inline-box;display:-ms-inline-flexbox;display:inline-flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;cursor:pointer}.toggle-component.is-disabled{opacity:.3}.toggle-component.is-disabled,.toggle-component.is-locked{cursor:not-allowed}.toggle-component__input{position:absolute;width:0;height:0;margin:0;opacity:0}.toggle-component__switch{position:relative;width:52px;height:32px;border-radius:16px;-webkit-transition:background-color .2s ease .1s;transition:background-color .2s ease .1s}.toggle-component__switch.is-checked:after{-webkit-transform:translateX(20px);transform:translateX(20px)}.is-short .toggle-component__switch{width:44px;height:24px}.is-short .toggle-component__switch:after{margin:-2px;-webkit-box-shadow:0 2px 6px 0 rgba(0,0,0,.16),0 0 1px 0 rgba(0,0,0,.1);box-shadow:0 2px 6px 0 rgba(0,0,0,.16),0 0 1px 0 rgba(0,0,0,.1)}.is-locked .toggle-component__switch:after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat;background-size:14px 14px}.toggle-component__switch:after{content:\"\";position:absolute;width:28px;height:28px;margin:2px;background-color:#fff;border-radius:100%;-webkit-box-shadow:0 4px 20px 0 rgba(0,0,0,.16),0 0 1px 0 rgba(0,0,0,.1);box-shadow:0 4px 20px 0 rgba(0,0,0,.16),0 0 1px 0 rgba(0,0,0,.1);-webkit-transition:-webkit-transform .2s cubic-bezier(1,0,0,1);transition:-webkit-transform .2s cubic-bezier(1,0,0,1);transition:transform .2s cubic-bezier(1,0,0,1);transition:transform .2s cubic-bezier(1,0,0,1),-webkit-transform .2s cubic-bezier(1,0,0,1)}.toggle-component__label{color:#34495e;font-size:14px;font-weight:500;line-height:1;-webkit-transition:color .2s ease .1s;transition:color .2s ease .1s}.toggle-component__label.is-active{color:#59a8ff}.toggle-component__label--left{margin-right:10px}.toggle-component__label--right{margin-left:10px}", ""]);
// Exports
module.exports = exports;
