import { createStore } from 'vuex'
import auth from './modules/auth'
import integrations from './modules/integrations'
import notifications from './modules/notifications'
import survey from './modules/survey'

export default createStore({
    modules: {
        auth,
        integrations,
        notifications,
        survey
    }
})
