<template>
    <aside class="aside-component" :style="style">
        <slot />
    </aside>
</template>

<script>
export default {
    name: 'AsideComponent',

    props: {
        width: {
            type: String,
            default: '430px'
        }
    },

    computed: {
        style() {
            const style = {}

            style.width = this.width

            return style
        }
    }
}
</script>

<style scope lang="scss">
.aside-component {
    display: flex;
    flex-direction: column;
}
</style>
