<template>
    <header class="header-component" :style="style">
        <slot />
    </header>
</template>

<script>
export default {
    name: 'HeaderComponent',

    props: {
        height: {
            type: String,
            default: '60px'
        }
    },

    computed: {
        style() {
            const style = {}

            style.height = this.height

            return style
        }
    }
}
</script>

<style lang="scss">
.header-component {
    display: flex;

    flex-shrink: 0;

    padding: 0 20px;
}
</style>
