<template>
    <button :class="classList" :type="nativeType" :autofocus="autofocus" :disabled="disabled">
        <svg
            v-if="loading"
            class="button-component__loading"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            <ellipse
                class="button-component__loading__ellipse"
                :style="style"
                cx="12"
                cy="12"
                rx="10"
                ry="10"
            />
        </svg>
        <template v-if="$slots.default">
            <slot />
        </template>
    </button>
</template>

<script>
export default {
    name: 'ButtonComponent',

    props: {
        nativeType: {
            type: String,
            default: 'button',
            validator(value) {
                return ['button', 'reset', 'submit'].includes(value)
            }
        },

        type: {
            type: String,
            default: 'default',
            validator(value) {
                return ['default', 'primary', 'success', 'info', 'warning', 'danger'].includes(
                    value
                )
            }
        },

        autofocus: {
            type: Boolean,
            default: false
        },

        round: {
            type: Boolean,
            default: false
        },

        circle: {
            type: Boolean,
            default: false
        },

        shadow: {
            type: Boolean,
            default: false
        },

        loading: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        isEmpty() {
            return !this.$slots.default
        },

        classList() {
            return [
                'button-component',
                this.type ? `button-component--${this.type}` : '',
                {
                    'is-round': this.round,
                    'is-circle': this.circle,
                    'has-shadow': this.shadow,
                    'is-loading': this.loading,
                    'is-disabled': this.disabled
                }
            ]
        }
    }
}
</script>

<style lang="scss">
.button-component {
    display: flex;
    align-items: center;
    justify-content: center;

    color: #fff;

    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    white-space: nowrap;

    padding: 14px 18px;

    background-color: #34495e;
    border: 0;
    border-radius: 6px;
    outline: 0;

    user-select: none;

    transition: all 0.2s ease;

    cursor: pointer;

    &:not(.is-disabled):not(.is-loading):hover {
        background-color: lighten(#34495e, 8);
    }

    &.is-round {
        padding: 14px 20px;

        border-radius: 40px;
    }

    &.is-circle {
        padding: 4px;

        border-radius: 50%;
    }

    &.has-shadow:not(.is-disabled):not(.is-loading):hover {
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

        transform: translateY(-1px);
    }

    &.is-loading,
    &.is-disabled {
        background-color: rgba(#34495e, 0.6);

        cursor: not-allowed;
    }
}

.button-component--primary {
    background-color: #59a8ff;

    &:not(.is-disabled):not(.is-loading):hover {
        background-color: lighten(#59a8ff, 8);
    }

    &.is-loading,
    &.is-disabled {
        background-color: rgba(#59a8ff, 0.6);
    }
}

.button-component--success {
    background-color: #2ecc71;

    &:not(.is-disabled):not(.is-loading):hover {
        background-color: lighten(#2ecc71, 8);
    }

    &.is-loading,
    &.is-disabled {
        background-color: rgba(#2ecc71, 0.6);
    }
}

.button-component--warning {
    background-color: #ffba59;

    &:not(.is-disabled):not(.is-loading):hover {
        background-color: lighten(#ffba59, 8);
    }

    &.is-loading,
    &.is-disabled {
        background-color: rgba(#ffba59, 0.6);
    }
}

.button-component--danger {
    background-color: #f75851;

    &:not(.is-disabled):not(.is-loading):hover {
        background-color: lighten(#f75851, 8);
    }

    &.is-loading,
    &.is-disabled {
        background-color: rgba(#f75851, 0.6);
    }
}

.button-component--info {
    color: #34495e;

    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);

    &:not(.is-disabled):not(.is-loading):hover {
        background-color: #fff;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }

    &.is-loading,
    &.is-disabled {
        background-color: #fff;
        opacity: 0.6;
    }
}

.button-component__loading {
    width: 16px;
    height: 16px;

    margin-right: 8px;
}

.button-component__loading__ellipse {
    stroke: #fff;
    stroke-width: 2px;

    animation: ellipse-tail 2.55s ease-in-out infinite, ellipse-rotation 0.7s linear infinite;
    transform-origin: 50% 50%;

    fill: transparent;
    stroke-dasharray: 60;
    stroke-dashoffset: 20;
    stroke-linecap: round;

    @keyframes ellipse-tail {
        0%,
        to {
            stroke-dashoffset: 5;
        }

        35%,
        45% {
            stroke-dashoffset: 57.2;
        }
    }

    @keyframes ellipse-rotation {
        0% {
            transform: scaleX(-1) rotate(0);
        }

        50% {
            transform: scaleX(-1) rotate(-180deg);
        }

        to {
            transform: scaleX(-1) rotate(-1turn);
        }
    }
}
</style>
