<template>
    <div class="warning-component">
        <ModalComponent ref="modal" width="600px" @closed="cancel">
            <template #header>
                <ImageComponent :src="`/img/modal/warning/${image}`" fit="cover">
                    <template #placeholder>
                        <LoadingComponent stroke-color="#579ae6" />
                    </template>
                    <template #error>
                        <ImageComponent src="/img/modal/error.png" fit="cover" />
                    </template>
                </ImageComponent>
            </template>
            <div class="warning-component__content">
                <div class="warning-component__markdown" v-html="markdown" />
            </div>
            <div class="warning-component__confirm">
                <div class="warning-component__confirm__inner">
                    <div class="warning-component__letters">
                        <div
                            v-for="(letter, index) in letters.split('')"
                            :key="index"
                            :class="[
                                'warning-component__letter',
                                {
                                    'is-active':
                                        value.charAt(index) === letter &&
                                        value.substr(0, index + 1) === letters.substr(0, index + 1)
                                }
                            ]"
                        >
                            {{ letter }}
                        </div>
                    </div>
                    <InputComponent
                        v-model="value"
                        placeholder="Type the above word to confirm"
                        align="center"
                        :maxlength="letters.length"
                        @input="
                            (input) => {
                                value = input.toUpperCase()
                            }
                        "
                        @keyup.enter="confirm"
                    />
                </div>
            </div>
            <template #footer>
                <div class="warning-component__actions">
                    <ButtonComponent shadow @click="cancel">Cancel</ButtonComponent>
                    <ButtonComponent
                        type="success"
                        :disabled="!lettersCheck"
                        shadow
                        @click="confirm"
                        >Confirm</ButtonComponent
                    >
                </div>
            </template>
        </ModalComponent>
    </div>
</template>

<script>
export default {
    name: 'WarningComponent',

    props: {
        word: {
            type: String,
            default: 'confirm'
        },

        name: {
            type: String,
            required: true
        },

        image: {
            type: String,
            default: 'warning.png'
        }
    },

    data() {
        return {
            markdown: '',
            value: '',
            resolve: ''
        }
    },

    computed: {
        markdownLoader() {
            return () =>
                this.name &&
                import(`@/assets/markdown/warning/${this.name}`).then((content) => {
                    return content.default
                })
        },

        letters() {
            return this.word.toUpperCase()
        },

        lettersCheck() {
            return this.value === this.letters
        }
    },

    async created() {
        const fetchedMarkdown = await this.markdownLoader()

        this.markdown = fetchedMarkdown
    },

    methods: {
        open() {
            this.$refs.modal.open()

            return new Promise((resolve) => {
                this.resolve = resolve
            })
        },

        close() {
            this.$refs.modal.close()
            this.value = ''
        },

        confirm() {
            if (!this.lettersCheck) return

            this.resolve(true)
        },

        cancel() {
            this.resolve(false)
        }
    }
}
</script>

<style lang="scss">
.warning-component {
    .modal-component__header {
        height: auto;
        min-height: 200px;

        padding: 0;
    }

    .modal-component__body {
        display: block;

        min-height: auto;

        overflow: hidden;

        padding-bottom: 0;
    }

    .modal-component__footer {
        justify-content: flex-end;

        padding: 30px;

        background-color: transparent;
        border: none;
        border-radius: 0 0 6px 6px;
    }

    .image-component {
        z-index: 0;

        height: auto;
    }

    .image-component__inner {
        border-radius: 6px 6px 0 0;
    }

    .input-component {
        input {
            font-weight: 500;
        }
    }
}

.warning-component__content {
    text-align: center;
    line-height: 1.5;

    padding: 25px 30px 10px;

    h2 {
        color: #34495e;

        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.5;

        margin-bottom: 15px;
        margin-top: 0 !important;
    }

    p {
        color: #2c3e50;

        font-size: 14px;

        padding-bottom: 15px;
        margin: 0 !important;

        &:last-child {
            padding-bottom: 0;
        }
    }

    strong {
        font-weight: 500;
    }

    a {
        color: #337ab7;

        text-decoration: none;
    }
}

.warning-component__confirm {
    display: flex;
    align-items: center;
    justify-content: center;
}

.warning-component__confirm__inner {
    display: inline-flex;

    flex-direction: column;
    align-items: center;

    margin: 0 auto;
    padding: 20px 30px;
}

.warning-component__letters {
    display: flex;

    margin-bottom: 20px;

    user-select: none;
}

.warning-component__letter {
    width: 38px;

    color: #fff;

    font-size: 15px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    line-height: 38px;

    margin: 0 4px;

    background-color: #2c3e50;
    border-radius: 6px;

    opacity: 0.6;

    transition: all 0.2s ease;

    &:first-of-type {
        margin-left: 0;
    }

    &:last-of-type {
        margin-right: 0;
    }

    &.is-active {
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

        opacity: 1;

        transform: translateY(-1px);
    }
}

.warning-component__actions {
    display: flex;

    width: 100%;

    .button-component {
        width: 100%;

        &:not(:first-child) {
            margin-left: 15px;
        }
    }

    .button-component--default {
        color: #34495e;

        background-color: #fff;
        border-color: rgba(0, 0, 0, 0.2);
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1) !important;

        &:hover {
            background-color: #fff !important;
            border-color: rgba(0, 0, 0, 0.25);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
        }
    }
}
</style>
