<template>
    <div class="prism-component">
        <div v-if="$slots.toolbar" class="prism-component__toolbar">
            <slot name="toolbar" />
        </div>
        <div class="prism-component__inner">
            <div class="prism-component__line-numbers">
                <div v-for="(line, index) in lines" :key="index">{{ line }}</div>
            </div>
            <pre ref="pre" v-html="content" />
        </div>
    </div>
</template>

<script>
import Prism from 'prismjs'

const getLanguage = (language) => {
    const languageMap = {
        vue: 'markup'
    }

    return languageMap[language] || language
}

export default {
    name: 'PrismComponent',

    props: {
        code: {
            type: String,
            default: ''
        },

        language: {
            type: String,
            default: 'vue'
        }
    },

    computed: {
        content() {
            const language = getLanguage(this.language)
            const code = Prism.highlight(this.code, Prism.languages[language], language)

            return `<code class="language-${language}">${code}</code>`
        },

        lines() {
            let lines = this.code.split(/\n/).length

            if (this.code.endsWith('\n')) lines--

            return lines
        }
    }
}
</script>

<style lang="scss">
.prism-component {
    position: relative;

    display: flex;

    font-size: 13px;
    line-height: 23px;

    background-color: #2c3e50;
    border-radius: 5px;

    pre {
        @include font-fira-code;

        width: 100%;

        color: #eaeaea;

        font-size: 13px;
        line-height: 23px;
        white-space: pre;
        tab-size: 4;
        hyphens: none;

        padding: 16px 0 0 24px;
        margin: 0;

        background-color: transparent;
        border-radius: 0;

        code {
            @include font-fira-code;

            white-space: pre;

            > *:last-child {
                padding-bottom: 16px;
            }
        }
    }

    .token.punctuation,
    .token.tag,
    .token.keyword,
    .token.operator,
    .token.boolean {
        color: #6a6aeb;
    }

    .token.attr-name,
    .token.string {
        color: #1abc9c;
    }

    .token.attr-value,
    .token.attr-value > .token.punctuation:not(:first-child),
    .token.function {
        color: #3498db;
    }

    .token.comment {
        color: #517394;
    }
}

.prism-component__toolbar {
    position: absolute;

    top: 8px;
    right: 8px;

    z-index: z-index('toolbar');
}

.prism-component__inner {
    display: flex;

    flex: 1;

    overflow: auto;
}

.prism-component__line-numbers {
    @include font-fira-code;

    position: relative;

    flex: 0 0 38px;

    color: #bdccdb;

    text-align: right;
    white-space: pre;

    padding-top: 16px;
    margin-right: 24px;

    user-select: none;
    pointer-events: none;

    > *:last-child {
        padding-bottom: 16px;
    }
}
</style>
