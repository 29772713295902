<template>
    <label :class="['radio-component', { 'is-active': isActive }, { 'is-disabled': isDisabled }]">
        <input
            v-model="modelValue"
            class="radio-component__radio"
            type="radio"
            :value="value"
            :name="name"
            :disabled="isDisabled"
            @change="handleChange"
        />
        <span class="radio-component__inner">
            <slot>
                {{ label }}
            </slot>
        </span>
    </label>
</template>
<script>
export default {
    name: 'RadioComponent',

    inject: {
        radioGroup: {
            default: ''
        }
    },

    props: {
        value: {
            type: String,
            default: ''
        },

        label: {
            type: String,
            default: ''
        },

        name: {
            type: String,
            default: ''
        },

        disabled: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        modelValue: {
            get() {
                return this.radioGroup.modelValue
            },

            set(value) {
                this.radioGroup.emitter.emit('input', value)
            }
        },

        isActive() {
            return this.modelValue === this.value
        },

        isDisabled() {
            return this.disabled || this.radioGroup.disabled
        }
    },

    methods: {
        handleChange() {
            this.radioGroup.emitter.emit('handleChange', this.value)
        }
    }
}
</script>

<style lang="scss">
.radio-component {
    flex-grow: 1;

    position: relative;

    display: inline-block;

    outline: none;

    &:first-child {
        .radio-component__inner {
            border-left: 1px solid #dcdfe6;
            border-radius: 4px 0 0 4px;
            box-shadow: none !important;
        }
    }

    &:last-child {
        .radio-component__inner {
            border-radius: 0 4px 4px 0;
        }
    }
}

.radio-component__radio {
    position: absolute;

    width: 0;
    height: 0;

    margin: 0;

    opacity: 0;
}

.radio-component__inner {
    display: inline-block;

    color: #34495e;

    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    white-space: nowrap;
    text-align: center;

    width: 100%;

    padding: 14px 18px;

    background-color: #fff;
    border: 1px solid #eaeaea;
    border-left: 0;

    user-select: none;

    transition: all 0.2s ease;

    cursor: pointer;

    &:not(.is-disabled):hover {
        color: #f90;
    }

    .is-active & {
        color: #fff !important;

        background-color: #f90;
        border-color: #f90 !important;
        box-shadow: -1px 0 0 0 #f90;
    }
}
</style>
