<template>
    <div
        :class="`row-component is-wrap-${wrap} is-justify-${justify}
    is-align-${align}`"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'RowComponent',

    props: {
        tag: {
            type: String,
            default: 'div'
        },

        wrap: {
            type: String,
            default: 'nowrap',
            validator(value) {
                return ['nowrap', 'wrap', 'wrap-reverse'].includes(value)
            }
        },

        justify: {
            type: String,
            default: 'start',
            validator(value) {
                return ['start', 'end', 'center', 'space-around', 'space-between'].includes(value)
            }
        },

        align: {
            type: String,
            default: 'start',
            validator(value) {
                return ['start', 'end', 'center', 'baseline', 'stretch'].includes(value)
            }
        }
    }
}
</script>

<style lang="scss">
.row-component {
    position: relative;

    display: flex;

    width: 100%;

    &.is-wrap-nowrap {
        flex-wrap: nowrap;
    }

    &.is-wrap-wrap {
        flex-wrap: wrap;
    }

    &.is-wrap-wrap-reverse {
        flex-wrap: wrap-reverse;
    }

    &.is-justify-start {
        justify-content: flex-start;
    }

    &.is-justify-end {
        justify-content: flex-end;
    }

    &.is-justify-center {
        justify-content: center;
    }

    &.is-justify-space-between {
        justify-content: space-between;
    }

    &.is-justify-space-around {
        justify-content: space-around;
    }

    &.is-align-start {
        align-items: flex-start;
    }

    &.is-align-end {
        align-items: flex-end;
    }

    &.is-align-center {
        align-items: center;
    }

    &.is-align-baseline {
        align-items: baseline;
    }

    &.is-align-stretch {
        align-items: stretch;
    }
}
</style>
