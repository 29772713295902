<template>
    <div
        :class="[
            'device-component',
            { 'is-phone': isPhone, 'is-tablet': isTablet, 'is-landscape': isLandscape }
        ]"
    >
        <div class="device-component__screen">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'DeviceComponent',

    props: {
        type: {
            type: String,
            default: 'phone',
            validator(value) {
                return ['phone', 'tablet'].includes(value)
            }
        },

        landscape: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        isPhone() {
            return this.type === 'phone'
        },

        isTablet() {
            return this.type === 'tablet'
        },

        isLandscape() {
            return this.landscape
        }
    }
}
</script>

<style lang="scss">
.device-component {
    padding: 8px;

    background-color: #f6f9fc;
    border-radius: 50px;
    box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3),
        inset 0 -2px 6px 0 rgba(10, 37, 64, 0.35);

    user-select: none;

    &.is-phone {
        width: 362px;
        height: 740px;
    }

    &.is-tablet {
        width: 500px;
        height: 740px;
    }

    &.is-landscape {
        &.is-phone {
            width: 740px;
            height: 362px;
        }

        &.is-tablet {
            width: 740px;
            height: 500px;
        }
    }
}

.device-component__screen {
    position: relative;

    height: 100%;

    background-color: #fff;
    border-radius: 42px;
}
</style>
