<template>
    <div class="column-component">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'ColumnComponent'
}
</script>
