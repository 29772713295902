<template>
    <div class="save-component">
        <InputComponent
            ref="input"
            v-model="inputValue"
            v-bind="$attrs"
            type="email"
            :placeholder="placeholder"
            @keyup.enter="handleSave"
        >
            <template #append>
                <RowComponent>
                    <transition name="fade-in">
                        <ButtonComponent
                            v-if="saveButtonVisible"
                            class="save-component__button"
                            tabindex="-1"
                            shadow
                            @click.stop="handleSave"
                        >
                            <i class="save-component__icon save-component__icon--save" />
                        </ButtonComponent>
                    </transition>
                    <ButtonComponent
                        class="save-component__button"
                        tabindex="-1"
                        shadow
                        @click.stop="handleDelete"
                    >
                        <i class="save-component__icon save-component__icon--trash" />
                    </ButtonComponent>
                </RowComponent>
            </template>
        </InputComponent>
    </div>
</template>

<script>
export default {
    name: 'SaveComponent',

    props: {
        value: {
            type: [String, Number],
            default: ''
        },

        placeholder: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            initialValue: this.value
        }
    },

    computed: {
        inputValue: {
            get() {
                return this.value
            },
            set(inputValue) {
                this.$emit('input', inputValue)
            }
        },

        saveButtonVisible() {
            const isTouched = this.initialValue !== this.value

            return this.value ? isTouched : false
        }
    },

    methods: {
        handleSave() {
            if (this.inputValue) {
                this.initialValue = this.value

                this.$emit('save', this.inputValue)
            }
        },

        handleDelete() {
            this.$emit('delete')
        }
    }
}
</script>

<style lang="scss">
.save-component {
    width: 100%;

    .button-component {
        padding: 12px;

        margin-left: 2px;
    }

    .fade-in-enter-active,
    .fade-in-leave-active {
        transition: all 0.2s ease;
    }

    .fade-in-enter,
    .fade-in-leave-to {
        opacity: 0;
    }
}

.save-component__button {
    color: #34495e;

    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1) !important;

    &:not(.is-disabled):not(.is-loading):hover {
        background-color: #fff;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
    }

    &.is-loading,
    &.is-disabled {
        background-color: rgba(#fff, 0.6);
    }

    .button-component__loading {
        width: 14px;
        height: 14px;

        margin: 0;
    }

    .button-component__loading__ellipse {
        stroke: #34495e;
    }
}

.save-component__icon {
    display: inline-block;

    width: 16px;
    height: 16px;

    margin-top: -2px;

    background-size: contain;
}

.save-component__icon--save {
    @include mask-icon('~@/assets/svg/icons/save.svg');

    background-color: #2ecc71;
}

.save-component__icon--trash {
    @include mask-icon('~@/assets/svg/icons/trash.svg');

    background-color: #f75851;
}
</style>
