import SurveyService from '@/services/survey-service.js'

const state = () => ({
    survey: {}
})

const getters = {
    survey: (state) => state.survey
}

const actions = {
    async getSurvey({ commit }, payload) {
        const { data } = await SurveyService.getSurvey(payload)

        commit('setSurvey', { survey: data })
    }
}

const mutations = {
    setSurvey(state, { survey }) {
        state.survey = survey
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
