<template>
    <div class="help-component">
        <PopoverComponent
            v-model:visible="visible"
            :width="width"
            :placement="placement"
            :close-delay="0"
        >
            <template #trigger>
                <div class="help-component__trigger" @click="onClick">?</div>
            </template>
            <div class="help-component__content" v-html="content" />
            <ButtonComponent v-if="externalLink" type="primary" shadow @click="onClickExternal">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="help-component__icon--external-link"
                >
                    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                    <polyline points="15 3 21 3 21 9"></polyline>
                    <line x1="10" y1="14" x2="21" y2="3"></line>
                </svg>
                <span>Learn more...</span>
            </ButtonComponent>
        </PopoverComponent>
    </div>
</template>

<script>
export default {
    name: 'HelpComponent',

    props: {
        width: {
            type: String,
            default: '400px'
        },

        placement: {
            type: String,
            default: 'bottom-start',
            validator(value) {
                return [
                    'top',
                    'top-start',
                    'top-end',
                    'bottom',
                    'bottom-start',
                    'bottom-end',
                    'left',
                    'left-start',
                    'left-end',
                    'right',
                    'right-start',
                    'right-end'
                ].includes(value)
            }
        },

        markdown: {
            type: String,
            required: true
        },

        externalLink: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            visible: false,
            content: ''
        }
    },

    computed: {
        markdownLoader() {
            return () =>
                import(`@/assets/markdown/help/${this.markdown}`).then((content) => {
                    return content.default
                })
        }
    },

    async created() {
        const fetchedMarkdown = await this.markdownLoader()

        this.content = fetchedMarkdown
    },

    methods: {
        onClick() {
            this.visible = !this.visible
        },

        onClickExternal() {
            window.open(this.externalLink)
        }
    }
}
</script>

<style scoped lang="scss">
.help-component {
    .popover-component {
        margin-right: 10px;
    }

    .button-component {
        padding: 14px 18px 14px 16px;
        margin-top: 20px;
    }
}

.help-component__content {
    display: flex;
    flex-direction: column;

    :deep(p) {
        margin-bottom: 20px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    :deep(strong) {
        font-weight: 500;
    }

    :deep(a) {
        color: #337ab7;

        text-decoration: none;

        &:hover {
            color: #23527c;
        }
    }
}

.help-component__trigger {
    display: flex;

    justify-content: center;
    align-items: center;

    width: 20px;
    height: 20px;

    color: #34495e;

    font-size: 13px;
    font-weight: 600;

    background-color: #fff;
    border: 1px solid darken(#f3f3f3, 4);
    border-radius: 10px;

    cursor: pointer;

    &:hover {
        color: #fff;

        background-color: #34495e;
        border-color: #34495e;
    }
}

.help-component__icon--external-link {
    margin: -2px 8px 0 0;
}
</style>
