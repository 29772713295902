<template>
    <main class="main-component">
        <slot />
    </main>
</template>

<script>
export default {
    name: 'MainComponent'
}
</script>

<style lang="scss">
.main-component {
    display: block;

    flex: 1;
    flex-basis: auto;

    overflow: auto;
}
</style>
