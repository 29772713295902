<template>
    <div :class="['divider-component', `divider-component--${direction}`]" :style="style" />
</template>

<script>
export default {
    name: 'DividerComponent',

    props: {
        color: {
            type: String,
            default: '#f3f3f3'
        },

        direction: {
            type: String,
            default: 'horizontal',
            validator(value) {
                return ['horizontal', 'vertical'].includes(value)
            }
        }
    },

    computed: {
        style() {
            const style = {}

            style.backgroundColor = this.color

            return style
        }
    }
}
</script>

<style lang="scss">
.divider-component {
    position: relative;

    &.divider-component--horizontal {
        display: block;

        width: 100%;
        height: 1px;

        margin: 24px 0;
    }

    &.divider-component--vertical {
        display: inline-block;

        width: 1px;
        height: 1em;

        margin: 0 8px;

        vertical-align: middle;
    }
}
</style>
