<script>
import { h } from 'vue'

export default {
    name: 'DraggableComponent',

    render() {
        return h(
            'ul',
            {},
            this.$slots.default().map((child, index) => {
                return h(
                    'li',
                    {
                        id: `q${index}`,
                        class: 'draggable-component',
                        draggable: true,
                        onDragstart: this.handleDragstart
                    },
                    [child]
                )
            })
        )
    },

    methods: {
        handleDragstart() {
            // this.emitter.emit('dragstart', event.target.outerHTML)
            // event.dataTransfer.effectAllowed = 'copy'
            // console.log(event.target.outerHTML)
            // event.dataTransfer.setData('text/html', event.target.outerHTML)
        }
    }
}
</script>

<style lang="scss">
.draggable-component {
    transform: translate(0, 0);
}
</style>
