// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/svg/icons/check.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/svg/icons/corner-down-right.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/svg/icons/corner-up-left.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".test-component .modal-component__header{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;height:auto;padding:30px;border-bottom:1px solid #f3f3f3}.test-component .modal-component__header .tag-component{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-size:13px;margin:0 4px}.test-component .modal-component__header .tag-component__icon{display:inline-block;width:14px;height:14px;background-size:contain;margin-right:6px}.test-component .modal-component__header .tag-component__icon--check{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat}.test-component .modal-component__body{min-height:auto;padding:30px}.test-component .column-component{width:100%}.test-component .column-component:not(:last-child){margin-right:10px}.test-component .prism-component{max-width:415px;height:400px}.test-component .prism-component:not(:last-child){height:200px;margin-bottom:5px}.test-component .prism-component .tag-component{color:#fff;background-color:rgba(30,42,54,.85)}.test-component__heading{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:#34495e;font-size:15px;font-weight:500;margin-bottom:10px}.test-component__icon{display:inline-block;width:16px;height:16px;margin:-2px 8px 0 0;background-size:contain}.test-component__icon--request{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-position:50%;background-repeat:no-repeat}.test-component__icon--response{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-position:50%;background-repeat:no-repeat}", ""]);
// Exports
module.exports = exports;
