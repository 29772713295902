import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

import store from '@/store'
import AuthService from '@/services/auth-service'

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET
}

const app = firebase.initializeApp(firebaseConfig)

let initiated = false
// https://medium.com/firebase-developers/why-is-my-currentuser-null-in-firebase-auth-4701791f74f0
// https://firebase.google.com/docs/auth/web/auth-state-persistence
app.auth().onAuthStateChanged(async (user) => {
    if (initiated) {
        return
    }

    initiated = true

    // Firebase already keeps session in local storage, try to use that first

    // Check that user saved in Firebase localstorage is the same user that we got permissions for
    if (user && isSameUser(user, store.getters['auth/user'])) {
        const jwt = await user.getIdToken()
        localStorage.setItem('firebaseSession2', jwt)

        return
    }

    // Get our custom jwt from firebaseSession2 in localStorage
    const jwt = getFirebaseJwt()

    if (!jwt) {
        // Refresh using API when no token in localStorage
        await refreshTokenUsingApi()

        return
    }

    // Try signing in with jwt from localStorage
    app.auth()
        .signInWithCustomToken(jwt)
        .then((firebaseUser) =>
            // Check that user saved in firebaseSession2 from localstorage is the same user that we got permissions for
            isSameUser(firebaseUser, store.getters['auth/user'])
                ? Promise.resolve(firebaseUser)
                : Promise.reject('Mismatching users in tokens')
        )
        // Refresh token using API when local storage token is expired
        .catch(refreshTokenUsingApi)
})

export const db = app.database()

export async function refreshTokenUsingApi() {
    const {
        data: { firebase: jwt }
    } = await AuthService.getFirebaseJwt()
    // const user = await app.auth().signInWithCustomToken(jwt)
    localStorage.setItem('firebaseSession2', jwt)

    return jwt
}

// This will refresh the jwt using firebase if it's about to expire, it's also possible to force refresh
export async function refreshTokenUsingFirebase(force = false) {
    return hasValidAuthToken()
        ? app.auth().currentUser.getIdToken(force)
        : Promise.reject('Invalid firebase session!')
}

export async function refreshToken(force = false) {
    try {
        const token = await refreshTokenUsingFirebase(force)
        localStorage.setItem('firebaseSession2', token)

        return token
    } catch (err) {
        return refreshTokenUsingFirebase(force)
    }
}

// This token is valid for one hour, it is only used to start the session with firebase,
// as long as firebase is active it will refresh access tokens.
// When refreshing the browser this token might not work to authenticate with firebase again, since more than one hour has passed
export function getFirebaseJwt() {
    return localStorage.getItem('firebaseSession2')
}

export function hasValidAuthToken() {
    return app && app.auth() && app.auth().currentUser
}

function isSameUser(firebaseUser, vuexUser) {
    const user_id = parseInt(`${firebaseUser.uid}`.replace(/[^0-9]/g, ''))
    return vuexUser.uid === user_id // TODO: compare level, email, period etc
}
